





















































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { Scrollbar } from '@/app/components';
import LibraryMenuFilters from './LibraryMenuFilters.vue';
import LibraryBlock from './LibraryBlock.vue';
import { useBlocks } from '../../composable/block';
import { Block } from '../../types';
import { BlockCategoryWrapper } from '../../constants';

export default defineComponent({
    name: 'GraphViewLibrary',
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
        framework: {
            type: String,
            required: true,
        },
        platform: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: { LibraryMenuFilters, LibraryBlock, Scrollbar },
    setup(props, { emit }) {
        const libraryIconHovered = ref(false);
        const searchText = ref<string>('');
        const activeFilter = ref('input');
        const { visibleBlocks } = useBlocks(searchText, activeFilter, props.framework, props.platform);

        const changeFilter = (filter: string) => {
            // if (!props.disableClickEvent) {
            activeFilter.value = filter;
            // }
        };

        const fullFilterName = computed(() => {
            const blockCategory = BlockCategoryWrapper.find(activeFilter.value);
            return blockCategory ? blockCategory.label : BlockCategoryWrapper.INPUT.label;
        });

        const createTask = (block: Block) => {
            emit('create-task', block);
        };

        return {
            emit,
            searchText,
            visibleBlocks,
            libraryIconHovered,
            changeFilter,
            fullFilterName,
            activeFilter,
            createTask,
        };
    },
});
