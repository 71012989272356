


















import { computed, defineComponent, ref } from '@vue/composition-api';
import Dag from './Dag.vue';
import { BlockCategoryWrapper } from '../../../constants';

export default defineComponent({
    name: 'Graph',
    props: {
        tasks: { type: Array, required: true },
        selectedTask: { type: String, required: false },
        action: { type: String, default: null },
        stopTaskClickPropagation: { type: Boolean, default: true },
        runningExecution: { type: Object, default: null },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
        pipelines: {
            type: Array,
            default: () => [],
        },
        readonly: { type: Boolean, default: false },
    },
    components: { Dag },
    setup(props, { emit }) {
        const dagData: any = ref({
            nodes: [],
            edges: [],
        });

        const filteredGraphData = computed(() => {
            dagData.value.nodes = [];
            dagData.value.edges = [];

            props.tasks.forEach((task: any) => {
                const toEdges = task.downstreamTaskIds;
                if (toEdges.length) {
                    toEdges.forEach((e: any) =>
                        dagData.value.edges.push({
                            from: task.id,
                            to: e,
                        }),
                    );
                }

                const blockCategoryWrapper: any = BlockCategoryWrapper.find(task.block.category);
                dagData.value.nodes.push({
                    id: task.id,
                    label: task.block.name,
                    category: task.block.category,
                    name: task.displayName,
                    blockCategory: blockCategoryWrapper,
                    createdAt: task.createdAt,
                    executions: task.executions,
                });
            });

            return dagData.value;
        });

        const showSettings = (id: string | number) => {
            const task: any = props.tasks.find((t: any) => t.id === id);
            emit('show-settings', task);
        };

        return {
            filteredGraphData,
            showSettings,
            emit,
        };
    },
});
