




















































































































































import * as R from 'ramda';
import { defineComponent, computed } from '@vue/composition-api';
import {
    BlockCategoryWrapper,
    BlockCategory,
    ExecutionTypeWrapper,
    ExecutionType,
    ExecutionStatus,
} from '../../constants';

export default defineComponent({
    name: 'TableViewTask',
    props: {
        task: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        highlighted: {
            type: Boolean,
            default: true,
        },
        stopClickPropagation: {
            type: Boolean,
            default: true,
        },
        columns: {
            type: Object,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    setup(props, { emit }) {
        const blockCategory = computed(() => BlockCategoryWrapper.find(props.task.block.category as BlockCategory));
        const blockType = computed(() => {
            if (props.task.block.type) {
                return props.task.block.type;
            }
            return null;
        });

        const label = computed(() => {
            if (blockType.value !== null) {
                return `${blockCategory.value?.label} (${blockType.value})`;
            }
            return blockCategory.value?.label;
        });

        const colour = computed(() => blockCategory.value?.colour);

        const iconHtml = computed(() => {
            return blockCategory.value?.iconHtml;
        });

        const taskRunningDryRun = computed(
            () =>
                props.runningExecution &&
                props.runningExecution.task &&
                props.runningExecution.task.id === props.task.id &&
                props.runningExecution.type === ExecutionType.Dry,
        );
        const taskRunningTestRun = computed(
            () =>
                props.runningExecution &&
                props.runningExecution.task &&
                props.runningExecution.task.id === props.task.id &&
                props.runningExecution.type === ExecutionType.Test,
        );

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            return null;
        });

        const numberOfColumns = computed(() => (!R.isNil(props.columns) ? Object.keys(props.columns).length : null));

        const hideStructureInformation = computed(
            () =>
                props.task.block.category === BlockCategory.Output ||
                (props.task.block.category === BlockCategory.MachineLearning && props.task.block.type === 'train'),
        );

        const failureMessage = computed(() => {
            if (props.task.executions.length > 0 && props.task.executions[0].status === ExecutionStatus.Failed) {
                const failureMessages = props.task.executions[0].logs.reduce(
                    (acc: string[], log: { level: string; message: string }) => {
                        if (log.level === 'error') acc.push(log.message);
                        return acc;
                    },
                    [],
                );
                return failureMessages.length > 0
                    ? failureMessages.join('. ')
                    : 'The latest run of this task has failed';
            }
            return null;
        });

        const showSettings = () => {
            emit('show-settings', props.task);
        };

        return {
            showSettings,
            failureMessage,
            label,
            colour,
            iconHtml,
            blockCategory,
            numberOfColumns,
            hideStructureInformation,
            runningExecutionTooltip,
            taskRunningDryRun,
            taskRunningTestRun,
        };
    },
});
