import { useAxios } from '@vue-composable/axios';
import { ref, Ref } from '@vue/composition-api';
import { AssetsAPI } from '@/modules/asset/api';

export function useResultAssetParameter(runnerId: Ref<any>, root: any) {
    const { exec } = useAxios(true);

    const assets = ref<any>();

    const refetch = () => {
        exec(AssetsAPI.getAvailableResults())
            .then((response: any) => {
                if (runnerId.value !== null) {
                    assets.value = response.data
                        .filter((asset: any) => asset.metadata.runnerId === runnerId.value)

                        .map((asset: any) => {
                            return { ...asset, id: asset.id.toString() };
                        });
                } else {
                    // For assets created with cloud execution or on-premise execution with cloud storage, assets have no filenames
                    assets.value = response.data
                        .filter((asset: any) => !asset.metadata.runnerId)
                        .map((asset: any) => {
                            return { ...asset, id: asset.id.toString() };
                        });
                }
            })
            .catch(() => {
                (root as any).$toastr.e('Error while trying to retrieve results', 'Error');
            });
    };

    refetch();
    return { assets, refetch };
}
