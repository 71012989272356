










































































































































import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationObserver } from 'vee-validate';
import { QueryBuilder } from '@/app/components';
import { useLogicalParameter, useParameter } from '@/modules/workflow-designer/composable';
import ComplexParameter from './ComplexParameter.vue';
import { InputParameter } from '../../../types';

export default defineComponent({
    name: 'LogicalParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        parameters: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        parametersDefinition: {
            type: Object,
            required: true,
        },
    },
    components: { QueryBuilder, ComplexParameter, ValidationObserver },
    setup(props, { emit }) {
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);
        const logicalParameterRef = ref<any>(null);
        const parameter = ref<any>(props.parameter);

        const { currentValue, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                return !R.isNil(incomingValue) && !R.isEmpty(incomingValue)
                    ? incomingValue
                    : { logical: 'AND', clauses: [] };
            },

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    emit('change', currentValue.value);
                }
            },
        );

        const {
            newConditionTemplate,
            updateConditions,
            updateGroups,
            preprocessCondition,
            isConditionCalculator,
            isGroupCalculator,
            snakeCase,
            newGroupTemplate,
            conditionsExtractor,
            themeCalculator,
            complexParameterIncomingProcessor,
            complexParameterOutgoingProcessor,
            hasValue,
            viewClause,
        } = useLogicalParameter(parameter as Ref<InputParameter>, currentValue);

        const parameterChange = () => {
            if (logicalParameterRef.value) {
                logicalParameterRef.value.validate();
            }
        };

        // Set the initial value on load
        change();

        return {
            currentValue,
            change,
            changeDate,
            newConditionTemplate,
            updateConditions,
            updateGroups,
            preprocessCondition,
            isConditionCalculator,
            isGroupCalculator,
            snakeCase,
            newGroupTemplate,
            conditionsExtractor,
            themeCalculator,
            complexParameterIncomingProcessor,
            complexParameterOutgoingProcessor,
            hasValue,
            viewClause,
            logicalParameterRef,
            parameterChange,
        };
    },
});
