










import { computed, defineComponent, onMounted, watch, ref } from '@vue/composition-api';
import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';
import dayjs from 'dayjs';
import * as R from 'ramda';
import Scrollbar from '@/app/components/Scrollbar.vue';
import { ExecutionStatus, ExecutionTypeWrapper } from '../../../constants';

const CANVAS_WIDTH = 12000;
const CANVAS_HEIGHT = 8000;

export default defineComponent({
    name: 'Dag',
    props: {
        tasks: { type: Object, required: true },
        selectedTask: { type: String, required: false },
        action: { type: String, default: null },
        stopClickPropagation: { type: Boolean, default: true },
        runningExecution: { type: Object, default: null },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
        pipelines: {
            type: Array,
            default: () => [],
        },
        readonly: { type: Boolean, default: false },
    },
    components: { Scrollbar },
    setup(props, { emit }) {
        const currentTasks = ref(R.clone(props.tasks));
        const currentRunningTask = ref(null);
        const nodes = computed(() => props.tasks.nodes);
        const edges = computed(() => props.tasks.edges);

        let inner: any = null;
        let g: any = null;
        let svg: any = null;
        let zoom: any = null;
        let ren: any = null;

        const graphNodeColours = (colour: string) => {
            switch (colour) {
                case 'purple':
                    return {
                        iconBackground: 'bg-purple-600',
                        borderColor: 'border-purple-600',
                        textColour: 'text-purple-600',
                    };

                case 'blue':
                    return {
                        iconBackground: 'bg-blue-600',
                        borderColor: 'border-blue-600',
                        textColour: 'text-blue-600',
                    };

                case 'green':
                    return {
                        iconBackground: 'bg-green-600',
                        borderColor: 'border-green-600',
                        textColour: 'text-green-600',
                    };

                case 'orange':
                    return {
                        iconBackground: 'bg-orange-600',
                        borderColor: 'border-orange-600',
                        textColour: 'text-orange-600',
                    };

                default:
                    return {
                        iconBackground: 'bg-red-600',
                        borderColor: 'border-red-600',
                        textColour: 'text-red-600',
                    };
            }
        };

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }

            return 'A run is currently in progress';
        });

        const invalidSvg = (tooltip: string) => {
            return `<div class="flex items-center pr-2"><svg
                                class="w-5 h-5 text-orange-500 cursor-help hover:text-orange-700"
                                fill="currentColor"
                                viewBox="0 0 20 18"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                            <title>${tooltip}</title>
                                <path
                                    fill-rule="evenodd"
                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg></div>`;
        };

        const hasFailureSvg = (failureMessages: string[]) => {
            return `<svg
                    class="w-5 h-5 text-red-400 cursor-help hover:text-red-700"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                <title>${
                    failureMessages.length > 0 ? failureMessages.join('. ') : 'The latest run of this task has failed'
                }</title>
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                    ></path>
                </svg>`;
        };

        const runningSvg = (colour: string, tooltip: any) => {
            return `<div class="pr-2">
                                    <svg
                                    class="w-5 h-5 animate-spin"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    >
                                        <circle
                                            class="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="${colour}"
                                            stroke-width="4"
                                        ></circle>
                                        <title>${tooltip}</title>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                            </div>`;
        };

        const graphNodeConfiguration = (task: any, iconBackground: string, borderColor: string, textColour: string) => {
            const running =
                props.runningExecution && props.runningExecution.task && props.runningExecution.task.id === task.id;
            const hasFailure = task.executions.length > 0 && task.executions[0].status === ExecutionStatus.Failed;
            let failureMessages = [];
            if (hasFailure) {
                failureMessages = task.executions[0].logs.reduce(
                    (acc: string[], log: { level: string; message: string }) => {
                        if (log.level === 'error') acc.push(log.message);
                        return acc;
                    },
                    [],
                );
            }
            let isValid = true;
            let validationErrorMsg = null;
            if (props.invalidTaskIds && props.invalidTaskIds.length) {
                isValid = !props.invalidTaskIds.includes(task.id);

                if (!isValid) {
                    const validationError: any = props.validationErrors.filter(
                        (vError: any) => vError.taskId === task.id,
                    );
                    validationErrorMsg = validationError[0].message;
                }
            }
            return {
                shape: 'rect',
                label: `<div class="flex col-span-1 bg-white rounded ">
                            <div class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium rounded-l ${iconBackground}">
                                <span>${task.blockCategory.iconHtml}</span>
                            </div>
                            <div class="flex items-center justify-between flex-1 truncate border-t border-b border-r rounded-r ${borderColor}">
                                <div class="flex flex-col flex-1 py-4 pl-4 mr-2 text-sm truncate" >
                                    <div class="${textColour} select-none">
                                        ${task.name ? task.name : task.label}
                                    </div>
                                </div>
                                <div class="${!isValid && hasFailure ? 'pr-1' : ''} ${
                    isValid && hasFailure ? 'pr-2' : ''
                }">${hasFailure && !running ? hasFailureSvg(failureMessages) : ''}</div>
                                ${!isValid && !running ? invalidSvg(validationErrorMsg) : ''}
                                ${running ? runningSvg(task.blockCategory.colour, runningExecutionTooltip.value) : ''}
                                ${running || !isValid || hasFailure ? '' : '<div class="w-7"></div>'}
                            </div>
                        </div>`,
                labelStyle: 'cursor:pointer; color:white; font-weight:bold;',
                labelType: 'html',
                padding: 1,
                ry: 5,
                rx: 5,
            };
        };

        const setClickEffectsOnNodes = () => {
            inner.selectAll('g.node').on('click', function selectNode(this: any, id: any) {
                if (props.stopClickPropagation) {
                    d3.event.stopPropagation();
                    d3.selectAll('rect').attr('class', 'label-container');
                    d3.select(this).select('rect').attr('class', 'selected label-container');
                    emit('show-settings', id);
                }
            });
        };

        /**
         * Calculates the current position/ scale of graph
         */
        const calculatePositionAndScale = () => {
            const currentCoords: any = inner.attr('transform').split(' ');
            const translate: any = currentCoords[0].split(/[(,)]/);

            return {
                translateWidth: translate[1],
                translateHeight: translate[2],
                scale: currentCoords[1].split(/[()]/)[1],
            };
        };

        const createGraph = () => {
            g = new dagreD3.graphlib.Graph({ compound: true }).setGraph({});
            g.graph().rankDir = 'LR';
            g.graph().marginx = 20;
            g.graph().marginy = 20;

            svg = d3.select('#dagre');
            inner = svg.append('g');

            // Set up zoom support
            zoom = d3.zoom().on('zoom', () => {
                inner.attr('transform', d3.event.transform);
            });

            // eslint-disable-next-line new-cap
            ren = new dagreD3.render();
        };

        const currentPipelines = ref<any>([]);
        const render = (update: boolean, newTask?: any) => {
            nodes.value.forEach((node: any) => {
                const { iconBackground, borderColor, textColour } = graphNodeColours(node.blockCategory.colour);
                g.setNode(node.id, graphNodeConfiguration(node, iconBackground, borderColor, textColour));
            });

            props.pipelines.forEach((pipeline: any) => {
                g.setNode(pipeline.name, {
                    label: pipeline.name,
                    labelStyle: 'opacity:0.5; font-weight:bold;',
                    clusterLabelPos: 'bottom',
                });

                pipeline.tasks.forEach((pTask: string | number) => {
                    g.setParent(pTask, pipeline.name);
                });
            });

            edges.value.forEach((edge: any) => {
                g.setEdge(edge.from, edge.to, {
                    label: '',
                    style: 'stroke: #4a5568;',
                    arrowheadStyle: 'fill:#4a5568;stroke:#4a5568;',
                });
            });

            let newPosition: any = null;
            if (update) {
                newPosition = calculatePositionAndScale();
                svg.attr('viewBox', null);
                svg.call(zoom.transform, d3.zoomIdentity.scale(1));
            }

            if (g) {
                ren(inner, g);
            }

            setClickEffectsOnNodes();
            inner.selectAll('g.node').select('rect').attr('stroke', 'transparent').attr('fill', 'transparent');

            if (update) {
                if (nodes.value.length) {
                    svg.call(
                        zoom.transform,
                        d3.zoomIdentity
                            .translate(newPosition.translateWidth, newPosition.translateHeight)
                            .scale(newPosition.scale),
                    );
                } else {
                    svg.call(zoom.transform, d3.zoomIdentity.translate(0, 0).scale(1));
                }

                if (newTask) {
                    inner
                        .selectAll('g.node')
                        .filter((nid: string) => nid === newTask.id)
                        .select('rect')
                        .attr('class', 'selected');
                }
            } else {
                const initialScale = 1;
                svg.call(zoom.transform, d3.zoomIdentity.translate(0, 0).scale(initialScale));
            }

            svg.attr('viewBox', `0 0 ${CANVAS_WIDTH} ${CANVAS_HEIGHT}`);
            svg.attr('width', CANVAS_WIDTH);
            svg.attr('height', CANVAS_HEIGHT);
        };

        onMounted(() => {
            createGraph();
            render(false);
        });

        const updateGraphNodes = (currentNodes: any, updatedNodes: any) => {
            let newTask: any = null;
            let deletedTask: any = null;
            updatedNodes.sort((t1: any, t2: any) => (dayjs.utc(t2.createdAt) > dayjs.utc(t1.createdAt) ? 1 : -1));
            // if a new block has been added
            if (updatedNodes.length > currentNodes.length) {
                newTask = { ...updatedNodes[0] };
                const { iconBackground, borderColor, textColour } = graphNodeColours(
                    updatedNodes[0].blockCategory.colour,
                );
                g.setNode(
                    updatedNodes[0].id,
                    graphNodeConfiguration(updatedNodes[0], iconBackground, borderColor, textColour),
                );
            } else {
                // if a block has been deleted
                currentNodes.forEach((cNode: any) => {
                    const exists = updatedNodes.some((uNode: any) => uNode.id === cNode.id);
                    if (!exists) {
                        deletedTask = cNode.id;
                    }
                });

                // if block was in pipeline, remove pipeline so it can be re-rendered
                let pipelineAffected = false;
                currentPipelines.value.forEach((cPipeline: any) => {
                    if (cPipeline.tasks.indexOf(deletedTask) !== -1) {
                        g.removeNode(cPipeline.name);
                        pipelineAffected = true;
                    }
                });

                g.removeNode(deletedTask);

                // remove all so pipeline cluster can be properly rendered
                if (pipelineAffected) {
                    inner.selectAll('g.clusters').remove();
                    inner.selectAll('g.nodes').remove();
                    inner.selectAll('g.edgePaths').remove();
                    inner.selectAll('g.edgeLabels').remove();
                }
            }

            render(true, newTask);
        };

        const updateGraphNodeConfig = (taskConfigChange: any, currentEdges: any) => {
            g.removeNode(taskConfigChange.id);
            const { iconBackground, borderColor, textColour } = graphNodeColours(taskConfigChange.blockCategory.colour);

            g.setNode(
                taskConfigChange.id,
                graphNodeConfiguration(taskConfigChange, iconBackground, borderColor, textColour),
            );

            const edgesToBeRedrawn = currentEdges.filter(
                (edge: any) => edge.from === taskConfigChange.id || edge.to === taskConfigChange.id,
            );

            edgesToBeRedrawn.forEach((dEdge: any) =>
                g.setEdge(dEdge.from, dEdge.to, {
                    label: '',
                    style: 'stroke: #4a5568;',
                    arrowheadStyle: 'fill:#4a5568;stroke:#4a5568;',
                }),
            );

            render(true);
        };

        const updateGraphEdges = (currentEdges: any, updatedEdges: any, taskConfigChange: any) => {
            const newEdges: any = [];
            const deletedEdges: any = [];
            // if new edge(s) has/ have been added
            updatedEdges.forEach((uEdge: any) => {
                const exists = currentEdges.some((cEdge: any) => JSON.stringify(uEdge) === JSON.stringify(cEdge));
                if (!exists) {
                    newEdges.push(uEdge);
                }
            });

            newEdges.forEach((nEdge: any) =>
                g.setEdge(nEdge.from, nEdge.to, {
                    label: '',
                    style: 'stroke: #4a5568;',
                    arrowheadStyle: 'fill:#4a5568;stroke:#4a5568;',
                }),
            );

            // if edge(s) has/ have been deleted
            currentEdges.forEach((cEdge: any) => {
                const exists = updatedEdges.some((uEdge: any) => JSON.stringify(cEdge) === JSON.stringify(uEdge));
                if (!exists) {
                    deletedEdges.push(cEdge);
                }
            });

            deletedEdges.forEach((dEdge: any) => g.removeEdge(dEdge.from, dEdge.to));

            if (newEdges.length || deletedEdges.length || taskConfigChange) {
                render(true);
                currentTasks.value.edges = [...updatedEdges];
            }
        };

        const updateGraph = (tasks: any) => {
            let taskConfigChange: any = null;

            const updatedNodes = tasks[0];
            const updatedEdges = tasks[1];

            const currentNodes = currentTasks.value.nodes;
            const currentEdges = currentTasks.value.edges;

            if (updatedNodes.length !== currentNodes.length) {
                // if a block has been added/ deleted
                updateGraphNodes(currentNodes, updatedNodes);
                currentTasks.value.nodes = [...updatedNodes];
            } else {
                // if the configuration (i.e. only display name of a task (for now)) has changed
                updatedNodes.forEach((uNode: any) => {
                    const displayNameChange = currentNodes.some(
                        (cNode: any) => uNode.id === cNode.id && uNode.name !== cNode.name,
                    );

                    const executionsChange = currentNodes.some(
                        (cNode: any) => JSON.stringify(cNode.executions) !== JSON.stringify(uNode.executions),
                    );

                    if (displayNameChange || executionsChange) {
                        taskConfigChange = uNode;
                    }
                });

                if (taskConfigChange) {
                    updateGraphNodeConfig(taskConfigChange, currentEdges);
                    currentTasks.value.nodes = [...updatedNodes];
                }
            }

            // if an edge has been added/ deleted or if a task has changed its input (i.e. its incoming edge)
            updateGraphEdges(currentEdges, updatedEdges, taskConfigChange);
        };

        watch(
            () => [props.tasks.nodes, props.tasks.edges],
            (tasks: any) => {
                if (g) {
                    updateGraph(tasks);
                }
            },
        );
        const currentInvalidTaskIds = ref([]);
        watch(
            () => props.invalidTaskIds,
            (invalidTaskIds: any) => {
                if (
                    (invalidTaskIds && invalidTaskIds.length && g) ||
                    (invalidTaskIds && !invalidTaskIds.length && currentInvalidTaskIds.value.length && g)
                ) {
                    render(true); // render(false) must check this again
                    currentInvalidTaskIds.value = invalidTaskIds;
                }
            },
        );

        watch(
            () => props.pipelines,
            (pipelines: any) => {
                // if (
                //     (pipelines && pipelines.length && g) ||
                //     (pipelines && !pipelines.length && currentPipelines.value.length && g)
                // ) {
                if (JSON.stringify(currentPipelines.value) !== JSON.stringify(props.pipelines) && g) {
                    // means that a config of a pipeline has changed (e.g. pipeline name, added/ deleted task)
                    let renamedPipeline = '';
                    if (currentPipelines.value.length === props.pipelines.length) {
                        currentPipelines.value.forEach((cPipeline: any) => {
                            props.pipelines.forEach((uPipeline: any) => {
                                // if pipeline was renamed, graph must be re-rendered
                                if (cPipeline.name !== uPipeline.name) {
                                    renamedPipeline = cPipeline.name;
                                    g.removeNode(renamedPipeline);

                                    inner.selectAll('g.clusters').remove();
                                    inner.selectAll('g.nodes').remove();
                                    inner.selectAll('g.edgePaths').remove();
                                    inner.selectAll('g.edgeLabels').remove();
                                }
                            });
                        });
                    }

                    render(true);
                }
                currentPipelines.value = R.clone(pipelines);
            },
            { deep: true }, // must check further
        );

        watch(
            () => props.runningExecution,
            (runExec: any) => {
                let runningTask = null;
                if (runExec && runExec.task && g) {
                    runningTask = props.tasks.nodes.find((uNode: any) => uNode.id === runExec.task.id);
                    currentRunningTask.value = runningTask;
                    updateGraphNodeConfig(runningTask, currentTasks.value.edges);
                } else if (!runExec && currentRunningTask.value) {
                    updateGraphNodeConfig(R.clone(currentRunningTask.value), currentTasks.value.edges);
                    currentRunningTask.value = null;
                }
            },
            { deep: true },
        );

        watch(
            () => props.selectedTask,
            (selectedT: any) => {
                if (selectedT && g) {
                    inner
                        .selectAll('g.node')
                        .filter((nid: string) => nid === selectedT)
                        .select('rect')
                        .attr('class', 'selected');
                }
                // if no task is selected, remove the class "selected" from all graph nodes
                if (!selectedT) {
                    // else {
                    d3.selectAll('rect').attr('class', 'label-container');
                }
            },
        );

        const executeGraphAction = (action: string) => {
            if (action && action !== 'TB' && action !== 'LR') {
                const newPosition = calculatePositionAndScale();
                let newScale = null;
                if (action === 'in') {
                    const zoomIn = 1.5 * newPosition.scale;
                    newScale = newPosition.scale < 3 ? zoomIn : newPosition.scale;
                } else if (action === 'out') {
                    const zoomOut = newPosition.scale / 1.5;
                    newScale = newPosition.scale > 0.3 ? zoomOut : newPosition.scale;
                } else if (action === 'restore') {
                    newScale = 1;
                    newPosition.translateWidth = 0;
                    newPosition.translateHeight = 0;
                } else {
                    // zoom graph to fit screen
                    newPosition.translateWidth = 0;
                    newPosition.translateHeight = 0;

                    const bounds = svg.node().getBBox();
                    const parent = svg.node().parentElement;
                    const fullWidth = parent.clientWidth;
                    const fullHeight = parent.clientHeight;

                    const [width, height] = [bounds.width, bounds.height];

                    const newScaleCalculation =
                        (0.95 / Math.max(width / fullWidth, height / fullHeight)) * newPosition.scale;

                    if (newScaleCalculation > 2) {
                        newScale = (0.85 / Math.max(width / fullWidth, height / fullHeight)) * newPosition.scale;
                    } else {
                        newScale = (0.95 / Math.max(width / fullWidth, height / fullHeight)) * newPosition.scale;
                    }
                }

                svg.call(
                    zoom.transform,
                    d3.zoomIdentity.translate(newPosition.translateWidth, newPosition.translateHeight).scale(newScale),
                );
            } else if (action) {
                // change graph orientation (Horizontal <-> Vertical)
                g.graph().rankDir = action;
                g.graph().transition = (selection: any) => selection.transition().duration(250);
                render(true);
                g.graph().transition = (selection: any) => selection.transition().duration(0);

                // TODO: fix pipeline name position
                // inner.selectAll('g.cluster').each(function (this: any) {
                //     // const clusterCurrentCoords = d3.select(this).attr('transform');
                //     if (d3.select(this).select('rect').attr('x')) {
                //         const xCoord: any = d3.select(this).select('rect').attr('x');
                //         // const yCoord: any = d3.select(this).select('rect').attr('y');
                //         const currentCoords: any = d3.select(this).select('g.label').select('g').attr('transform');
                //         const translate: any = currentCoords.split(/[(,)]/);
                //         const yCoord = translate[2];
                //         d3.select(this)
                //             .select('g.label')
                //             .select('g')
                //             .attr('transform', `translate(${parseInt(xCoord, 10) + 10},${parseInt(yCoord, 10) - 10})`);
                //     }
                // });
            }
            emit('reset-action');
        };

        watch(
            () => props.action,
            (action: string) => {
                executeGraphAction(action);
            },
        );
    },
});
