export const ExecutionPlatforms = [
    {
        name: 'Cloud',
        description: 'Processing will be performed in platform',
        platform: 'kubernetes',
        runner: false,
    },
    {
        name: 'Local',
        description: 'Processing will be performed locally, on your device',
        platform: 'docker',
        runner: true,
    },
    {
        name: 'Edge',
        description: 'Processing will be performed on an edge device',
        platform: 'edge',
        runner: true,
    },
];
