import { computed, Ref, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { InputParameter } from '../../types';
import { S } from '@/app/utilities';

export function useParameter(
    parameter: InputParameter,
    value: Ref<any>,
    visible: Ref<boolean>,
    sendChange: any,
    findInitialValue: any,
    changeFunc: any,
) {
    // The last change datetime to force update
    const changeDate = ref<Date>(new Date());

    // The value object of the parameter
    const currentValue = ref<any>(findInitialValue(visible.value ? value.value : null, parameter));

    // Computes a list of possible values this parameter can take if fixed values
    // provided
    const fixedList = computed(() => {
        if (
            !R.isNil(parameter.validation) &&
            S.has('values', parameter.validation) &&
            !R.isNil(parameter.validation.values) &&
            !R.isEmpty(parameter.validation.values)
        ) {
            return (
                !R.isNil(parameter?.validation) &&
                (parameter.validation as any).values.reduce((acc: any[], availableValue: any) => {
                    if (R.is(Object, availableValue)) {
                        acc.push({
                            label:
                                S.has('text', availableValue) &&
                                !R.isNil(availableValue.text) &&
                                !R.isEmpty(availableValue.text)
                                    ? availableValue.text
                                    : availableValue.value,
                            value: availableValue.value,
                        });
                    } else {
                        acc.push({ label: availableValue, value: availableValue });
                    }
                    return acc;
                }, [])
            );
        }
        return [];
    });

    // Calculate if the value is an array or not
    const isArray = computed(() => R.is(Array, currentValue.value));

    // In case this is a multiple parameter add an entry
    const addAnother = () => {
        if (!currentValue.value) {
            currentValue.value = [];
        }
        const valClone = R.clone(currentValue.value);
        (valClone as any[]).push(null);
        sendChange(valClone);
    };

    // In case this is a multiple parameter then
    // remove the entry
    const deleteEntry = (index: number) => {
        if (currentValue.value) {
            const valClone = R.clone(currentValue.value);
            (valClone as string[]).splice(index, 1);
            sendChange(valClone);
        }
    };

    // Triggered when there is a change in the value
    const change = (...args: any[]) => {
        changeFunc(...args);
        changeDate.value = new Date();
    };

    // Watch changes in the incoming value to adapt the currently held value
    watch(
        () => value.value,
        (newValue: any) => {
            currentValue.value = findInitialValue(newValue, parameter);
        },
    );

    // Watch visibility change. If visibility becames false
    // at any time then change the value of the parameter to
    // whatever null means for the particular parameter
    watch(
        () => visible.value,
        (isVisible: any) => {
            if (!isVisible) {
                currentValue.value = findInitialValue(null, parameter);
                change();
            }
        },
    );
    if (!visible.value) change();

    return { currentValue, findInitialValue, fixedList, isArray, changeDate, addAnother, deleteEntry, change };
}
