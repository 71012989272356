import { S } from '@/app/utilities';
import { useAxios } from '@vue-composable/axios';
import { computed, Ref, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { RetrievalApi, VisualisationAPI, WorkflowAPI } from '../api';
import {
    ApexChartType,
    ApexChartXAxisValidValues,
    bucketProps,
    BucketUnit,
    defaultSeriesOptions,
    pageNumber,
    pageSize,
    YAxisSeriesSchema,
} from '../constants';
import { BucketProps, ChartConfig, Visualisation, XAxisSeriesOption } from '../types';

// useVisualisation can be used without input, if only methods are needed to be used
export function useVisualisation(
    tasks: Array<any> | null = null,
    selectedTask: Readonly<any> | null = null,
    visualisationConfig: Ref<ChartConfig | null> = ref(null),
) {
    const axiosRunner = useAxios(true);

    const getVisualisations = (workflowId: string) => axiosRunner.exec(WorkflowAPI.getVisualisations(workflowId));

    const getVisualisationData = (payload: Array<any>) => axiosRunner.exec(RetrievalApi.getData(payload));

    const getVisualisation = (id: any) => axiosRunner.exec(VisualisationAPI.get(id));

    const createVisualisation = (visualisationPayload: any) =>
        axiosRunner.exec(VisualisationAPI.create(visualisationPayload));

    const updateVisualisation = (visualisation: Visualisation) =>
        axiosRunner.exec(VisualisationAPI.update(visualisation));

    const deleteVisualisation = (id: any) => axiosRunner.exec(VisualisationAPI.delete(id));

    const getVisualisationWorkflow = (id: string) => axiosRunner.exec(WorkflowAPI.getWorkflow(id));

    const upstreamTask = computed(() => {
        const temp: any = tasks?.find((task: any) => task.id === selectedTask?.value?.upstreamTaskIds[0]);
        return temp || {};
    });

    const seriesStructure = computed(() => {
        const structure = upstreamTask?.value?.executions?.length
            ? upstreamTask.value.executions.reduce((acc: any, curr: any) => {
                  if (curr.result?.structure) {
                      acc.push(curr.result?.structure);
                  }
                  return acc;
              }, [])
            : [];
        return structure;
    });

    // Data type validation for series input.
    const isVisualisationConfigValid = computed(() => {
        if (!visualisationConfig?.value || !seriesStructure?.value.length) return false;
        const validXAxis =
            !visualisationConfig.value.seriesOptions.groupBy?.fields.length || // No check if zero values for XAxis
            visualisationConfig.value.seriesOptions.groupBy.fields.length > 1 || // If groupBy field are more than 1, then data type doesn't affect result (due to BE implementation)
            visualisationConfig.value.seriesOptions.groupBy.fields.every((field: any) => {
                // Check if data type has changed during workflow editing
                return (
                    R.hasIn(field, seriesStructure.value[0]) &&
                    visualisationConfig.value?.seriesOptions.groupBy.type === seriesStructure.value[0][field]
                );
            });
        const validYAxis =
            !visualisationConfig.value.seriesOptions.series.length || // No check if zero values
            visualisationConfig.value.seriesOptions.series.every((y: any) => {
                // Check if data type has changed during workflow editing
                return R.hasIn(y.field, seriesStructure.value[0]) && y.type === seriesStructure.value[0][y.field];
            });
        return validXAxis && validYAxis;
    });

    const getRetrievalConfig = (config: any, assetId: any): any => {
        const { chart, seriesOptions } = config;
        const retrieval: any = {
            assetId,
            type: `${chart.getChartType()}`.toUpperCase(),
            groupBy: null,
            series: [],
            filters: seriesOptions.filters,
            pageSize: seriesOptions.pageSize,
            pageNumber: pageNumber.DEFAULT,
            label: chart.getChartType() === ApexChartType.RadialBar ? seriesOptions.label : undefined,
        };

        seriesOptions.series.forEach((option: any): void => {
            if (option.field) {
                retrieval.series.push({
                    field: option.field,
                    function: option.function,
                    name: option.name,
                    filters: option.filters,
                    type: option.type,
                });

                if (seriesOptions.groupBy?.fields.length) {
                    // In case we need data type category
                    // let dataType = '';
                    // Object.keys(ApexChartXAxisValidValues).forEach((key) => {
                    //     if (ApexChartXAxisValidValues[key].includes(seriesOptions.groupBy.type)) {
                    //         dataType = key;
                    //     }
                    // });

                    retrieval.groupBy = {
                        ...retrieval.groupBy,
                        name: seriesOptions.groupBy.name,
                        fields: seriesOptions.groupBy.fields,
                        type: seriesOptions.groupBy.type,
                        buckets: seriesOptions.groupBy.buckets,
                    };
                }
            }
        });
        return retrieval;
    };

    // Single input
    const calculateCount = (data: any, param: string): any => {
        const aggregationData = data.reduce((result: any, currentVal: any) => {
            const value = currentVal[param];
            const resultIndex = result.findIndex((x: any) => S.has(value, x));
            const tempVal: any = result;
            if (resultIndex === -1) {
                tempVal.push({ [value]: 1 });
                return tempVal;
            }
            tempVal[resultIndex] = { [value]: tempVal[resultIndex][value] + 1 };

            return tempVal;
        }, []);

        const temp = R.fromPairs(aggregationData.map((obj: any) => R.toPairs(obj)[0]));
        const values = R.values(temp);
        const labels = R.keys(temp).map((x: any) => (x === 'null' ? 'No value available' : x));

        return {
            labels,
            values,
        };
    };

    // Single input
    const calculateSum = (data: any, param: string, groupBy: any): any => {
        const aggregationData = data.reduce((result: any, currentVal: any) => {
            const seriesValue = currentVal[param];
            const value = currentVal[groupBy[0]];
            const resultIndex = result.findIndex((x: any) => S.has(value, x));
            const tempVal: any = result;
            if (resultIndex === -1) {
                tempVal.push({ [value]: seriesValue || 0 });
                return tempVal;
            }
            tempVal[resultIndex] = { [value]: tempVal[resultIndex][value] + seriesValue || 0 };

            return tempVal;
        }, []);

        const temp = R.fromPairs(aggregationData.map((obj: any) => R.toPairs(obj)[0]));
        const values = R.values(temp);
        const labels = R.keys(temp).map((x: any) => (x === 'null' ? 'No value available' : x));

        return {
            labels,
            values,
        };
    };

    const computeAggregation = (
        data: any = [],
        param: string,
        aggregationFunction: string,
        groupBy?: Array<XAxisSeriesOption> | null,
    ): any => {
        switch (aggregationFunction) {
            case 'count': {
                return calculateCount(data, param);
            }
            case 'sum': {
                return calculateSum(data, param, groupBy);
            }
            default:
                return { labels: [], values: [] };
        }
    };

    // Default values based on x-axis type
    const defaultUndefinedValue = (xAxisType: string, indexVal: number) => {
        switch (xAxisType) {
            case 'datetime':
            case 'category':
                return '';
            case 'numeric':
            default:
                return indexVal;
        }
    };

    const computeResultsViewVisualisationData = (inputParams: any): any => {
        const { config, width, height, sampleData } = inputParams;
        if (!config || !width || !height || !sampleData) {
            return null;
        }

        const mappedData = config.seriesOptions?.series.map((option: any) => {
            let seriesData = [];
            if (config.chart.iBelongInThePieFamily()) {
                const aggregatedData = computeAggregation(
                    sampleData,
                    option.field,
                    option.function,
                    config.seriesOptions.groupBy?.fields,
                );
                seriesData = aggregatedData.values;
                config.configuration.labels = aggregatedData.labels;
            } else {
                seriesData = sampleData.reduce((current: Array<any>, item: any, index: number) => {
                    if (option.field) {
                        // x-axis value must not be null because datapoint cannot be rendered. We check if groupBy field value exists or we return a default x-axis value based on x-axis type (categorical, numeric, datetime)
                        const x =
                            config.seriesOptions.groupBy?.fields[0] && item[config.seriesOptions.groupBy.fields[0]]
                                ? item[config.seriesOptions.groupBy.fields[0]]
                                : defaultUndefinedValue(config.configuration.xaxis.type, index);
                        // Bar chart needs x-axis/groupBy values to be strings
                        if (config.chart.getChartType() === ApexChartType.Bar) {
                            current.push({ x: `${x}`, y: item[option.field] || null });
                        } else current.push({ x, y: item[option.field] || null });
                    }

                    return current;
                }, []);
            }
            return { name: option.name, data: seriesData };
        });

        const data = {
            height,
            width,
            type: config.chart.getChartType(),
            options: config.configuration,
            series: config.chart.iBelongInThePieFamily() ? mappedData[0]?.data : mappedData,
        };
        return data;
    };

    const computeVisualisationData = (inputParams: any): any => {
        const { configuration, width, height, visualisationData, isPie } = inputParams;
        if (!configuration?.retrieval || !width || !height || !visualisationData) {
            return null;
        }

        const seriesOptions = {
            groupBy: configuration?.retrieval?.groupBy,
            series: configuration?.retrieval?.series,
            filters: configuration?.retrieval?.filters,
            label: configuration?.retrieval?.label,
        };

        const seriesData: Array<any> = [];
        // TODO: use records/pagesize

        Object.keys(visualisationData).forEach((key: any) => {
            if (configuration.configuration.type === ApexChartType.RadialBar) {
                const data = visualisationData[key];
                seriesData.push(data);
                configuration.configuration.options.labels = [key];
            } else {
                // Find series data based on series name
                if (seriesOptions.series.findIndex((x: any) => x.name === key) !== -1) {
                    const values = visualisationData[key];
                    // PIE mapping
                    if (isPie) {
                        seriesData.push(...values.series);
                        configuration.configuration.options.labels = values.labels.map((label: any) =>
                            R.is(String, label) ? label : `${label}`,
                        );
                        // RADAR mapping
                    } else if (configuration.configuration.type === ApexChartType.Radar) {
                        seriesData.push({
                            name: key,
                            data: [...values.series],
                        });
                        configuration.configuration.options.labels = values.name.map((label: any) =>
                            R.is(String, label) ? label : `${label}`,
                        );
                        // HEATMAP mapping
                    } else if (configuration.configuration.type === ApexChartType.Heatmap) {
                        values.forEach((dataSet: any, index: number) => {
                            // If there is no name available use default
                            seriesData.push({
                                ...dataSet,
                                name: R.isNil(dataSet.name) ? `series-${index}` : dataSet.name,
                            });
                        });
                        // BAR mapping
                    } else if (configuration.configuration.type === ApexChartType.Bar) {
                        // Add distinct labels
                        values.forEach((value: any) => {
                            const label = `${value.x}`;
                            if (!configuration.configuration.options.labels.includes(label)) {
                                configuration.configuration.options.labels.push(label);
                            }
                        });
                        // Create data array
                        seriesData.push({
                            name: key,
                            data: values.map((value: any) => value.y),
                        });
                    } else {
                        seriesData.push({
                            name: key,
                            data: values.filter((value: any) => value.x != null), //In case BE sends invalid value for x-axis. undefined will cause apexcharts to crash and null cannot be rendered
                        });
                    }
                }
            }
        });

        const data = {
            height,
            width,
            type: configuration.configuration.type,
            options: configuration.configuration.options,
            series: seriesData,
        };
        return data;
    };

    const addSeriesBuckets = (dataType?: any): BucketProps | null => {
        if (ApexChartXAxisValidValues.datetime.includes(dataType)) {
            return { ...bucketProps.datetime };
        }
        if (ApexChartXAxisValidValues.numeric.includes(dataType)) {
            return { ...bucketProps.numeric };
        }
        return null;
    };

    const getBucketUnitOptions = (): any[] => {
        const temp: Array<any> = [];
        Object.keys(BucketUnit).forEach((key) => {
            temp.push({ label: key, value: BucketUnit[key] });
        });
        return temp;
    };

    const getSeriesOptions = (hasSeriesSection: boolean, chartType: ApexChartType): any => {
        if (hasSeriesSection) {
            return R.clone({
                ...defaultSeriesOptions,
                series: [{ ...YAxisSeriesSchema }],
                pageSize: pageSize[chartType] || pageSize.DEFAULT,
            });
        }
        return R.clone({ ...defaultSeriesOptions, pageSize: pageSize[chartType] || pageSize.DEFAULT });
    };

    const downloadChart = async (
        chartRef: any,
        logo: any,
        outputName = 'Chart',
        widthAdjust: any = 0.12,
        heightAdjust: any = 2,
    ): Promise<any> => {
        if (!chartRef) return;
        try {
            const data = await chartRef.dataURI();
            const c = document.createElement('canvas');
            const ctx = c.getContext('2d');
            const imageObj1 = new Image();
            const imageObj2 = new Image();
            imageObj1.src = data?.imgURI;
            imageObj1.onload = () => {
                if (!ctx) return;
                ctx.canvas.width = imageObj1.width;
                ctx.canvas.height = imageObj1.height;
                ctx.drawImage(
                    imageObj1,
                    0,
                    2, // remove grey line created at export
                    imageObj1.width,
                    imageObj1.height - 2,
                    0,
                    0,
                    imageObj1.width,
                    imageObj1.height,
                );
                imageObj2.src = logo;
                imageObj2.onload = () => {
                    const logoWidth = widthAdjust * imageObj1.width;
                    const logoHeight = Math.round(logoWidth / heightAdjust);
                    ctx.drawImage(imageObj2, (1 - widthAdjust) * imageObj1.width, 0, logoWidth, logoHeight);
                    const img = c.toDataURL('image/png');
                    const downloadLink = document.createElement('a');
                    downloadLink.href = img;
                    downloadLink.download = outputName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                };
            };
        } catch (e) {
            // console.log(e);
        }
    };

    const loading = computed(() => axiosRunner.loading.value);
    const error = computed(() => axiosRunner.error.value);

    return {
        getVisualisations,
        createVisualisation,
        updateVisualisation,
        deleteVisualisation,
        getVisualisationData,
        getVisualisation,
        getVisualisationWorkflow,
        loading,
        error,
        isVisualisationConfigValid,
        seriesStructure,
        getRetrievalConfig,
        computeResultsViewVisualisationData,
        computeVisualisationData,
        addSeriesBuckets,
        getBucketUnitOptions,
        getSeriesOptions,
        downloadChart,
    };
}
