import { useAxios } from '@vue-composable/axios';
import { computed, ref, Ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { useErrors, useQuery, useResult } from '@/app/composable';
import { ExecutionStatusWrapper, WorkflowSortingOption } from '../constants';
import GET_WORKFLOWS from '../graphql/workflows.graphql';
import { Workflow } from '../types';

export function useWorkflows(pageSize: number, searchText: Ref<string>, sortBy: Ref<WorkflowSortingOption>) {
    const { checkGQLAuthentication } = useErrors();
    const apolloRunner = useQuery(GET_WORKFLOWS, {}, { fetchPolicy: 'no-cache' });
    const axiosRunner = useAxios();
    apolloRunner.onError(checkGQLAuthentication);

    const workflows = ref<Workflow[]>([]);
    const page = ref<number>(1);

    const setPage = (newPage: number) => {
        page.value = newPage;
    };

    apolloRunner.onResult(() => {
        const fetchedWorkflows = useResult(apolloRunner.result, null, (data: any) => data.workflows)
          .value as Workflow[];
        const statusMap = ExecutionStatusWrapper.map();
        workflows.value = fetchedWorkflows.reduce((acc: Workflow[], workflow: Workflow) => {
            let statusPriority = 100;
            if (workflow.executions.length > 0) {
                statusPriority = statusMap[workflow.executions[0].status];
            }
            acc.push({
                ...workflow,
                statusPriority,
            });
            return acc;
        }, []);
    });

    const filteredWorkflows = computed(() => {
        let workflowsClone = R.clone(workflows.value);

        if (searchText.value.trim() !== '') {
            workflowsClone = (workflowsClone as Workflow[]).filter((workflow: Workflow) =>
              R.toLower(workflow.name).includes(R.toLower(searchText.value)),
            );
        }

        const sortCriteria: any[] = [];
        sortBy.value.fieldPaths.forEach((path: any[]) => {
            sortCriteria.push(sortBy.value.asc ? R.ascend(R.path(path)) : R.descend(R.path(path)));
        });
        return R.sortWith(sortCriteria)(workflowsClone) as Workflow[];
    });

    const visibleWorkflows = computed(() => {
        return filteredWorkflows.value.slice((page.value - 1) * pageSize, (page.value - 1) * pageSize + pageSize);
    });

    const errors = computed(() => {
        const errorsList = [];
        if (axiosRunner.error.value) {
            errorsList.push(axiosRunner.error.value.message);
        }

        if (apolloRunner.error.value) {
            errorsList.push(apolloRunner.error.value.message);
        }

        return errorsList;
    });

    const loading = computed(() => axiosRunner.loading.value || apolloRunner.loading.value);

    const { refetch } = apolloRunner;
    const { exec } = axiosRunner;

    watch(
      () => [searchText.value, sortBy.value],
      () => {
          setPage(1);
      },
    );

    return { workflows, filteredWorkflows, visibleWorkflows, errors, loading, page, setPage, refetch, exec };
}
