export const ProcessingSteps: { name: string; description: string; blockId: string; component?: any }[] = [
    {
        name: 'Mapping',
        description: 'Map your data to the common data model',
        blockId: 'dc.DataMapper',
    },
    {
        name: 'Cleaning',
        description: 'Detect and address data quality issues, such as corrupt or inaccurate records',
        blockId: 'dc.DataCleaner',
    },
    {
        name: 'Anonymisation',
        description: 'Anonymise your data to prevent an individual from being identified',
        blockId: 'dc.DataAnonymiser',
    },
    {
        name: 'Encryption',
        description: 'Encrypt your data to make it unreadable to a person or entity without permission',
        blockId: 'dc.DataEncryptor',
    },
];
