import * as R from 'ramda';
import { Chart } from './charts/chart';

export class ChartConfig {
    readonly visualisationId: string | null = null;

    readonly title: string | null | undefined;

    readonly chart: Chart;

    readonly configuration: any;

    readonly seriesOptions: any;

    constructor(
        chart: Chart,
        title: string | null | undefined = null,
        configuration: any = null,
        visualisationId: string | null = null,
        seriesOptions: any = null,
    ) {
        this.chart = chart;
        this.title = title;
        this.configuration = configuration || this.chart?.configuration(this.title);
        this.visualisationId = visualisationId;
        this.seriesOptions = seriesOptions;
    }

    isSaved() {
        return !R.isNil(this.visualisationId);
    }
}
