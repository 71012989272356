














import { defineComponent, ref, watch, onMounted } from '@vue/composition-api';
import * as R from 'ramda';
import apexchart from 'vue-apexcharts';

export default defineComponent({
    name: 'ApexChart',
    model: {
        prop: 'chartRef',
        event: 'update-chart-ref',
    },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
    },
    components: { apexchart },
    setup(props, { emit }) {
        const chartDataRef = ref<any>(R.clone(props.chartData));
        const ApexChartRef = ref<any>(null);
        const refresh = async () => {
            ApexChartRef.value.destroy();
            ApexChartRef.value.init().then(() => {
                console.log('refreshed');
            });
        };
        watch(
            () => props.chartData,
            (updatedChartData: any) => {
                const clonedData = R.clone(updatedChartData);
                chartDataRef.value = clonedData;
                // Resolves render issues on updating x-axis type-options
                if (ApexChartRef.value) {
                    ApexChartRef.value.destroy();
                    chartDataRef.value = clonedData;
                    ApexChartRef.value.init().then(() => {
                        // eslint-disable-next-line no-underscore-dangle
                        const isInfinity = ApexChartRef.value?._data.chart.w.globals.xRange === Infinity;
                        if (isInfinity) {
                            // 2nd init fixes missing minX property that sets xRnage = Infinity
                            refresh();
                        }
                    });
                }
            },
            { deep: true },
        );

        onMounted(() => emit('update-chart-ref', ApexChartRef.value));
        return {
            chartDataRef,
            ApexChartRef,
        };
    },
});
