












































































































































































import * as R from 'ramda';
import { defineComponent, computed, ref } from '@vue/composition-api';
import { Scrollbar } from '@/app/components';
import { ExecutionTypeWrapper, ExecutionStatus, ExecutionType } from '../../constants';
import { Task } from '../../types';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'RunningExecutionFooter',
    props: {
        runningExecution: {
            type: Object,
            default: null,
        },
        pendingExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        workflowId: {
            type: String,
            required: true,
        },
        otherRunningExecutions: { type: Array, default: () => [] },
    },
    components: { Scrollbar },
    setup(props, { root }) {
        const expand = ref<boolean>(false);

        const executionText = (execution: { type: ExecutionType; task: Task }) => {
            const executionType = ExecutionTypeWrapper.find(execution.type);
            return executionType?.message(ExecutionStatus.Queued, execution.task).message;
        };

        const hasHistoryEntry = (execution: { type: ExecutionType; task: Task }) => {
            return !R.isNil(execution) && R.isNil(execution.task);
        };

        const canExpand = computed(() => props.pendingExecutions.length > 0);

        const pendingExecutionsWithMessages = computed(() => {
            return props.pendingExecutions.map((execution: any) => {
                return { ...execution, message: executionText(execution), hasHistory: hasHistoryEntry(execution) };
            });
        });

        const goToHistoryEntry = (execution: { type: ExecutionType; task: Task }) => {
            if (execution) {
                root.$router.push({
                    name: 'history',
                    params: { workflowId: props.workflowId },
                });
            }
        };

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution) && S.has('task', props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            return null;
        });

        const runningExecutionHasHistory = computed(() =>
            !R.isNil(props.runningExecution)
                ? hasHistoryEntry(props.runningExecution as { type: ExecutionType; task: Task })
                : null,
        );

        const runningExecutionsWithMessages = computed(() => {
            const otherExecutionsWithMessages = props.otherRunningExecutions.map((execution: any) => {
                return { ...execution, message: executionText(execution), hasHistory: hasHistoryEntry(execution) };
            });

            if (R.isNil(props.runningExecution)) {
                return otherExecutionsWithMessages;
            }

            return [
                {
                    ...props.runningExecution,
                    runninghasHistory: runningExecutionHasHistory.value,
                    message: runningExecutionTooltip.value,
                },
                ...otherExecutionsWithMessages,
            ];
        });

        return {
            expand,
            canExpand,
            goToHistoryEntry,
            ExecutionType,
            runningExecutionsWithMessages,
            pendingExecutionsWithMessages,
            runningExecutionTooltip,
            runningExecutionHasHistory,
        };
    },
});
