import * as R from 'ramda';
import { computed, Ref, ref } from '@vue/composition-api';
import { useQuery, useResult, useErrors } from '@/app/composable';
import GET_TASK_TABLE_VIEW from '../graphql/task-table-view.graphql';
import { Execution, SampleConfiguration, Task } from '../types';
import { S } from '@/app/utilities';

export function useTaskTableView(taskId: string) {
    const { checkGQLAuthentication } = useErrors();
    const apolloRunner = useQuery(GET_TASK_TABLE_VIEW, { id: taskId }, { fetchPolicy: 'no-cache' });
    apolloRunner.onError(checkGQLAuthentication);

    const task = ref<Task | null>(null);
    const latestNormalExecution: Ref<any> = ref<Execution | null>(null);
    const latestNormalExecutionSample = ref<any>(null);
    const latestNormalExecutionStructure = ref<any>(null);
    const latestNormalExecutionSampleConfiguration = ref<SampleConfiguration | null>(null);
    apolloRunner.onResult(() => {
        const lastExecutions = useResult(apolloRunner.result, null, (data: any) => data.task.executions)
            .value as Execution[];
        task.value = useResult(apolloRunner.result, null, (data: any) => data.task).value as Task;

        latestNormalExecution.value = !R.isNil(lastExecutions) && lastExecutions.length > 0 ? lastExecutions[0] : null;

        if (
            !R.isNil(latestNormalExecution.value) &&
            S.has('result', latestNormalExecution.value) &&
            !R.isNil(latestNormalExecution.value.result) &&
            S.has('sample', latestNormalExecution.value.result) &&
            !R.isNil(latestNormalExecution.value.result.sample)
        ) {
            latestNormalExecutionSample.value = latestNormalExecution.value.result.sample;
        } else {
            latestNormalExecutionSample.value = null;
        }

        if (
            !R.isNil(latestNormalExecution.value) &&
            S.has('result', latestNormalExecution.value) &&
            !R.isNil(latestNormalExecution.value.result) &&
            S.has('structure', latestNormalExecution.value.result) &&
            !R.isNil(latestNormalExecution.value.result.structure)
        ) {
            latestNormalExecutionStructure.value = latestNormalExecution.value.result.structure;
        } else {
            latestNormalExecutionStructure.value = null;
        }

        if (latestNormalExecution.value && latestNormalExecution.value.configuration?.sample) {
            latestNormalExecutionSampleConfiguration.value = latestNormalExecution.value.configuration?.sample;
        } else {
            latestNormalExecutionSampleConfiguration.value = null;
        }
    });

    const errors = computed(() => {
        const errorsList = [];
        if (apolloRunner.error.value) {
            errorsList.push(apolloRunner.error.value.message);
        }

        return errorsList;
    });

    const loading = computed(() => apolloRunner.loading.value);

    const { refetch, onError } = apolloRunner;

    return {
        latestNormalExecution,
        latestNormalExecutionSample,
        latestNormalExecutionStructure,
        latestNormalExecutionSampleConfiguration,
        loading,
        refetch,
        onError,
        errors,
        task,
    };
}
