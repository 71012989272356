
































































































































import { defineComponent, PropType, computed, onMounted } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationProvider, extend } from 'vee-validate';
import { v4 as uuidv4 } from 'uuid';
import { PlusIcon } from '@vue-hero-icons/outline';
import { XCircleIcon } from '@vue-hero-icons/solid';
import { TwSelect, TwInput } from '@/app/components';
import { requiredValidator } from '@/app/validators';
import { AggregationFunction, VisualisationOptionsDescription } from '@/modules/workflow-designer/constants';
import { YAxisSeriesOption } from '@/modules/workflow-designer/types';
import Filters from './filters/Filters.vue';

extend('required', requiredValidator);

export default defineComponent({
    name: 'ChartConfigurationSeries',
    model: {
        prop: 'chartConfig',
        event: 'update-chart-series',
    },
    props: {
        chart: {
            type: Object,
            required: true,
        },
        chartConfig: {
            type: Array as PropType<YAxisSeriesOption[]>,
            required: true,
        },
        availableSeries: {
            type: Array,
            default: () => [],
        },
        showAddName: {
            type: Boolean,
            default: true,
        },
        inputTitle: {
            type: String,
            default: 'Name',
        },
        validationRules: {
            type: Object,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    components: { TwSelect, TwInput, ValidationProvider, PlusIcon, Filters, XCircleIcon },
    setup(props, { emit }) {
        const selectInputUuid = `field_${uuidv4()}`;
        const nameInputUuid = `field_${uuidv4()}`;

        const editedChartSeries = computed({
            get: () => props.chartConfig,
            set: (updatedChartSeries: any) => {
                emit('validate-inputs');
                emit('update-chart-series', updatedChartSeries);
            },
        });

        const handleAddSeries = () => {
            if (props.availableSeries.length) {
                editedChartSeries.value = [
                    ...editedChartSeries.value,
                    {
                        name: '',
                        chartType: null,
                        field: '',
                        type: null,
                        function: null,
                        filters: {
                            operator: 'AND',
                            conditions: [],
                        },
                    },
                ];
            }
        };

        const handleRemoveSeries = (seriesIndex: number) => {
            const temp = [...editedChartSeries.value];
            temp.splice(seriesIndex, 1);
            editedChartSeries.value = temp;
        };

        const showAddSeries = computed(() => {
            // TODO: Limit max series input?
            return true;
        });

        const handleChangeField = (value: any, index: number) => {
            // Assign datatype to series field
            const selectedOption: any = props.availableSeries.find((x: any) => x.label === value);
            const temp = [...editedChartSeries.value];
            const updatedItem = {
                ...editedChartSeries.value[index],
                type: selectedOption.value,
                field: value,
            };
            temp[index] = updatedItem;
            editedChartSeries.value = temp;
        };

        const aggregationFunctionsOptions = computed(() =>
            props.chart
                ? props.chart.getAggregationFunctions().map((func: AggregationFunction) => {
                      return {
                          label: func.label,
                          value: func.key,
                      };
                  })
                : [],
        );
        onMounted(() => emit('validate-inputs'));

        return {
            R,
            uuidv4,
            handleAddSeries,
            editedChartSeries,
            handleRemoveSeries,
            showAddSeries,
            handleChangeField,
            selectInputUuid,
            nameInputUuid,
            aggregationFunctionsOptions,
            VisualisationOptionsDescription,
        };
    },
});
