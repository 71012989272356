import { IngestionMethods } from '@/modules/apollo/constants/ingestion-methods.constants';
import { ProcessingSteps } from '@/modules/apollo/constants/processing-steps.constants';
import { OutputMethods } from '@/modules/apollo/constants/output-methods.constants';

export const AvailableTaskTypes: Record<
    string,
    { name: string; description: string; blockId: string; component?: any }
> = [...IngestionMethods, ...ProcessingSteps, ...OutputMethods].reduce(
    (acc: any, task: { name: string; description: string; blockId: string; component?: any }) => {
        acc[task.blockId] = task;
        return acc;
    },
    {},
);
