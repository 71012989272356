import * as R from 'ramda';
import { ValidatorUtils } from './utils';

export const maxValueValidator = {
    message(field: string, args: any) {
        return `'${ValidatorUtils.prettifyName(field)}' has a max value of ${args[0]}`;
    },
    validate: (value: any, args: any) => {
        if (R.isNil(value) || R.isEmpty(value) || R.isNil(args) || R.isEmpty(args) || !R.is(Number, args[0]))
            return false;
        return value <= args[0];
    },
};
