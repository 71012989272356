






































































































































import { defineComponent, computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { extend } from 'vee-validate';
import { TwInput, ConfirmButton } from '@/app/components';
import { integerValidator } from '@/app/validators';
import { useParameter } from '@/modules/workflow-designer/composable';
import { InputParameter } from '@/modules/workflow-designer/types';
import ParameterValidation from '../ParameterValidation.vue';
import { S } from '@/app/utilities';

extend('numeric', integerValidator);

export default defineComponent({
    name: 'IntegerParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    components: { ParameterValidation, TwInput, ConfirmButton },
    setup(props, { emit }) {
        const NUMBER_REGEX = new RegExp(/^-?\d+$/);

        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);

        const isNumber = (valToCheck: any) =>
            !R.isNil(valToCheck) && !R.isEmpty(valToCheck) && NUMBER_REGEX.test(String(valToCheck));

        const { currentValue, isArray, addAnother, deleteEntry, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                if (!S.has('value', incomingValue) || R.isNil(incomingValue?.value)) {
                    // Case no existing value defined
                    if (S.has('default', props.parameter.validation)) {
                        // Case default value defined in validation information
                        if (props.parameter.multiple) {
                            if (
                                R.isNil(props.parameter.validation.default) ||
                                R.isEmpty(props.parameter.validation.default)
                            ) {
                                return [];
                            }

                            if (R.is(Array, props.parameter.validation.default)) {
                                return props.parameter.validation.default.map((defaultVal: any) =>
                                    isNumber(defaultVal) ? Number(defaultVal) : null,
                                );
                            }
                            return isNumber(props.parameter.validation.default)
                                ? [Number(props.parameter.validation.default)]
                                : [];
                        }
                        return isNumber(props.parameter.validation.default)
                            ? Number(props.parameter.validation.default)
                            : null;
                    }
                    // Case no default value defined in validation information then take default empty value
                    return props.parameter.multiple ? [] : null;
                }
                if (S.has('value', incomingValue) && incomingValue?.value && props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking multiple values
                    return R.is(Array, incomingValue.value)
                        ? incomingValue.value.map((v: any) => (NUMBER_REGEX.test(String(v)) ? Number(v) : 0))
                        : [isNumber(incomingValue.value) ? Number(incomingValue.value) : 0];
                }
                if (!R.isNil(incomingValue.value) && !props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking a single value
                    return isNumber(incomingValue.value) ? Number(incomingValue.value) : null;
                }
                return props.parameter.multiple ? [] : null;
            },

            // on value change
            (checkChange = true) => {
                if (!R.isNil(currentValue)) {
                    let newValue = null;
                    if (props.parameter.multiple) {
                        if (
                            currentValue.value &&
                            !R.isNil(currentValue.value) &&
                            !R.isEmpty(currentValue.value) &&
                            R.is(Array, currentValue.value)
                        ) {
                            newValue = (currentValue.value as number[]).map((v: any) => parseInt(String(v), 10));
                        } else if (
                            currentValue.value &&
                            !R.isNil(currentValue.value) &&
                            !R.isEmpty(currentValue.value) &&
                            !R.is(Array, currentValue.value)
                        ) {
                            newValue = isNumber(currentValue.value) ? [parseInt(String(currentValue.value), 10)] : [];
                        } else {
                            newValue = [];
                        }
                    } else {
                        newValue = isNumber(currentValue.value) ? parseInt(String(currentValue.value), 10) : null;
                    }
                    if (!checkChange || JSON.stringify(newValue) !== JSON.stringify(currentValue.value)) {
                        emit('change', { value: newValue });
                    }
                }
            },
        );

        const step = computed(() => {
            if (
                S.has('validation', props.parameter) &&
                !R.isNil(props.parameter.validation) &&
                !R.isEmpty(props.parameter.validation) &&
                S.has('range', props.parameter.validation) &&
                !R.isNil(props.parameter.validation.range) &&
                !R.isEmpty(props.parameter.validation.range) &&
                S.has('step', props.parameter.validation.range) &&
                !R.isNil(props.parameter.validation.range.step) &&
                !R.isEmpty(props.parameter.validation.range.step)
            ) {
                return props.parameter.validation.range.step;
            }
            return null;
        });

        // Set the initial value on load
        change(false);

        return { currentValue, isArray, addAnother, deleteEntry, change, step, changeDate };
    },
});
