



















import { computed, defineComponent, PropType } from '@vue/composition-api';
import { ColorSwatchIcon } from '@vue-hero-icons/outline';
import { SampleConfiguration } from '../../types';

export default defineComponent({
    name: 'SampleDescription',
    props: {
        configuration: {
            type: Object as PropType<SampleConfiguration>,
            required: true,
        },
    },
    components: { ColorSwatchIcon },
    setup(props) {
        const description = computed(() => {
            if (props.configuration.size.rowsCount !== null) {
                if (props.configuration.selection === 'random')
                    return `Using a sample of ${props.configuration.size.rowsCount} random rows of the overall dataset`;
                if (props.configuration.selection === 'first')
                    return `Using a sample of the first ${props.configuration.size.rowsCount} rows of the overall dataset`;
                if (props.configuration.selection === 'last')
                    return `Using a sample of the last ${props.configuration.size.rowsCount} rows of the overall dataset`;
            } else if (props.configuration.size.percentage !== null) {
                if (props.configuration.selection === 'random')
                    return `Using a random sample of ${
                        props.configuration.size.percentage * 100
                    }% of the dataset's total rows`;
                if (props.configuration.selection === 'first')
                    return `Using a sample of the first ${
                        props.configuration.size.percentage * 100
                    }% of the dataset's total rows`;
                if (props.configuration.selection === 'last')
                    return `Using a sample of the last ${
                        props.configuration.size.percentage * 100
                    }% of the dataset's total rows`;
            }
            return '';
        });
        return { description };
    },
});
