



























































import { defineComponent, computed } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { v4 as uuidv4 } from 'uuid';
import { TwSelect, TwInput, Toggle } from '@/app/components';
import {
    requiredValidator,
    minValueValidator,
    maxValueValidator,
    minListLengthValidator,
    maxListLengthValidator,
    regexValidator,
    excludedValidator,
} from '@/app/validators';

extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);
extend('required', requiredValidator);
extend('minList', minListLengthValidator);
extend('maxList', maxListLengthValidator);
extend('regex', regexValidator);
extend('excluded', excludedValidator);

export default defineComponent({
    name: 'ChartConfigurationInput',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object,
        },
        inputType: {
            type: String,
            required: true,
        },
        param: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        isCol: {
            type: Boolean,
            default: false,
        },
        isNumber: {
            type: Boolean,
            default: false,
        },
        validationRules: {
            type: Object,
        },
        fieldName: {
            type: String,
            default: 'This field',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
        },
    },
    components: { TwSelect, TwInput, ValidationProvider, Toggle },
    setup(props, { emit }) {
        const uuid = `field_${uuidv4()}`;

        const editedValue = computed({
            get: () => props.configuration,
            set: (updatedConfiguration: any) => {
                emit('change', updatedConfiguration);
            },
        });

        const handlePreprocess = (val: any): any => {
            if (props.isNumber) {
                return parseInt(val || 0, 10);
            }
            return val;
        };

        return { editedValue, uuid, handlePreprocess };
    },
});
