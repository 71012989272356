





























import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { TwSelect } from '@/app/components';
import { useDatasetAssetParameter, useParameter } from '@/modules/workflow-designer/composable';
import { InputParameter } from '@/modules/workflow-designer/types';
import ParameterValidation from '../ParameterValidation.vue';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'DatasetAssetParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        forceUpdate: {
            type: Date,
            default: () => {
                return new Date();
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        runnerId: {
            type: String,
            default: null,
        },
    },
    components: { TwSelect, ParameterValidation },
    setup(props, { emit, root }) {
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);
        const runnerId = ref<string>(props.runnerId);

        const { assets } = useDatasetAssetParameter(runnerId, root);
        const { currentValue, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                let resultingValue = null;
                if (incomingValue && S.has('value', incomingValue) && !R.isNil(incomingValue.value)) {
                    // Case of an existing value already defined
                    resultingValue = `${incomingValue.value}`;
                } else if (S.has('default', props.parameter.validation)) {
                    // Case of no value defined but default is available
                    resultingValue = props.parameter.validation.default;
                }
                return resultingValue;
            },

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    const assetId =
                        R.isNil(currentValue.value) || R.isEmpty(currentValue.value)
                            ? null
                            : parseInt(currentValue.value, 10);
                    emit('change', { value: assetId });
                }
            },
        );

        // Set the initial value on load
        change();

        return { currentValue, change, assets, changeDate };
    },
});
