import * as R from 'ramda';

export class ValidatorUtils {
    static prettifyName(name: string): string {
        if (R.isNil(name) || R.isEmpty(name)) {
            return name;
        }

        return name
            .split('_')
            .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
            .join(' ');
    }
}
