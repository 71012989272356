import * as R from 'ramda';
import { computed, ref, Ref } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { Task, ValidationError, Workflow } from '../types';
import { WorkflowAPI } from '../api';

export function useValidator(workflow: Ref<Workflow>, taskMap: Ref<Map<string, Task>>) {
    const axiosRunner = useAxios();

    const workflowDags = ref<any>([]);
    const validationErrors = ref<ValidationError[]>([]);
    const invalidTaskIds = computed(() =>
        R.isNil(validationErrors.value) ? [] : R.reject(R.isNil)(R.pluck('taskId')(validationErrors.value)),
    );

    const runValidation = async () => {
        await axiosRunner.exec(WorkflowAPI.validate(workflow.value.id)).then((resValidation: any) => {
            validationErrors.value = [];
            if (!R.isNil(resValidation)) {
                for (let e = 0; e < Object.keys(resValidation.data).length; e++) {
                    const uuid = Object.keys(resValidation.data)[e];
                    const issues = resValidation.data[uuid];
                    if (workflow.value.id === uuid) {
                        workflowDags.value = issues.dags;

                        if (issues.cycles.length > 0) {
                            validationErrors.value.push({
                                major: true,
                                message: `The workflow has ${issues.cycles.length} ${
                                    issues.cycles === 1 ? 'cycle' : 'cycles'
                                }`,
                                taskId: null,
                            });
                        }
                        if (issues.dags.length > 1) {
                            validationErrors.value.push({
                                major: true,
                                message: `The workflow has ${issues.dags.length} different chains. You can have a single chain only`,
                                taskId: null,
                            });
                        }
                    } else if (taskMap.value.has(uuid)) {
                        if (!issues.isValid) {
                            const task: Task = taskMap.value.get(uuid) as Task;
                            validationErrors.value.push({
                                major: false,
                                message: `Task '${task.displayName}' is missing required parameters`,
                                taskId: uuid,
                            });
                        }

                        if (issues.isModelValid === false) {
                            const task: Task = taskMap.value.get(uuid) as Task;
                            validationErrors.value.push({
                                major: false,
                                message: `The model used in task '${task.displayName}' has been deprecated. Please update the task configuration.`,
                                taskId: uuid,
                            });
                        }
                    }
                }
            } else {
                validationErrors.value.push({
                    major: true,
                    message: 'An error occured while trying to validate',
                    taskId: null,
                });
                throw new Error('An error occured while trying to validate');
            }
        });
    };

    return { validationErrors, invalidTaskIds, workflowDags, runValidation };
}
