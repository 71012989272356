import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/apollo';

export default {
    templates: () => ({ method: 'GET', url: `${endpoint}/templates` } as AxiosRequestConfig),
    create: (templateId: string, configuration: Record<string, any>) =>
        ({
            method: 'POST',
            url: `${endpoint}/template/${templateId}`,
            data: configuration,
        } as AxiosRequestConfig),
    retrieve: (workflowId: string) => ({ method: 'GET', url: `${endpoint}/job/${workflowId}` } as AxiosRequestConfig),
    update: (workflowId: string, data: { name: string; description: string }) =>
        ({ method: 'PUT', url: `${endpoint}/job/${workflowId}`, data } as AxiosRequestConfig),
    delete: (workflowId: string) => ({ method: 'DELETE', url: `${endpoint}/job/${workflowId}` } as AxiosRequestConfig),
    finalise: (workflowId: string, data: { assetIds: any; storageLocation: string }) =>
        ({ method: 'PATCH', url: `${endpoint}/job/${workflowId}/finalise`, data } as AxiosRequestConfig),
    updateTask: (taskId: string, data: { configuration: Record<string, any> }) =>
        ({
            method: 'PUT',
            url: `${endpoint}/task/${taskId}`,
            data,
        } as AxiosRequestConfig),
};
