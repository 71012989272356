










































































































































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api';
import * as R from 'ramda';
import { InformationCircleIcon } from '@vue-hero-icons/outline';
import { GeneralAlert } from '@/app/components';
import { logoBase64 } from './assets/logo/logoBase64';
import { ApexChart } from './charts';
import { WarningMessages, ExecutionStatus, ExecutionType, VisualisationExecutionTypeWrapper } from '../../constants';
import { Task } from '../../types';
import { useVisualisation } from '../../composable';

export default defineComponent({
    name: 'ResultsViewMain',
    props: {
        width: {
            type: Number,
            required: true,
        },
        height: {
            type: Number,
            required: true,
        },
        upstreamTask: {
            type: Object as PropType<Task | null>,
            default: null,
        },
        selectedTask: {
            type: Object as PropType<Task | null>,
        },
        warningMessage: {
            type: String,
            default: '',
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        chartConfig: {
            type: Object,
        },
        invalidConfiguration: {
            type: Boolean,
        },
    },
    components: { GeneralAlert, ApexChart, InformationCircleIcon },
    setup(props, { emit }) {
        const chartRef = ref<any>({});

        const { computeResultsViewVisualisationData, downloadChart } = useVisualisation();

        const inputParams = computed(() => ({
            config: props.chartConfig,
            width: props.width,
            height: props.height,
            sampleData: props.upstreamTask?.executions?.length ? props.upstreamTask.executions[0].result?.sample : [],
        }));

        const chartData = computed(() => computeResultsViewVisualisationData(inputParams.value));

        const testRun = () => {
            emit('test-run');
        };
        const resetZoom = () => {
            if (chartRef.value) chartRef.value.resetSeries(false, true);
        };
        // const zoomIn = () => {};

        const isTaskRunning = computed(
            () =>
                !R.isNil(props.runningExecution) &&
                !R.isNil(props.runningExecution.task) &&
                props.runningExecution.task.id === props.upstreamTask?.id,
        );

        const runningExecutionImage = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                if (props.runningExecution.status === ExecutionStatus.Queued) {
                    return 'queue';
                }
                if (props.runningExecution.type === ExecutionType.Dry) {
                    return 'building';
                }
                return 'calculating';
            }
            return null;
        });

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                const executionType = VisualisationExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(
                    props.runningExecution.status,
                    props.runningExecution.task,
                    null,
                    props.selectedTask,
                ).message;
            }
            return 'A run is currently in progress';
        });

        const showChart = computed(() => {
            return !props.invalidConfiguration && !props.warningMessage && props.chartConfig && chartData.value;
        });

        const mainPanelMessage = computed(() => {
            if (props.readOnly) {
                return WarningMessages.DEPRECATED_WORKFLOW;
            }
            if (props.invalidConfiguration) {
                return WarningMessages.INVALID_CONFIGURATION;
            }
            return WarningMessages.DEFAULT;
        });

        watch(
            () => props.runningExecution,
            (newRunningExecution: any) => {
                const isRunning =
                    !R.isNil(newRunningExecution) &&
                    !R.isNil(newRunningExecution.task) &&
                    newRunningExecution.task.id === props.upstreamTask?.id;
                emit('running-execution-status', isRunning);
            },
        );
        return {
            chartData,
            downloadChart,
            logoBase64,
            chartRef,
            resetZoom,
            testRun,
            WarningMessages,
            isTaskRunning,
            runningExecutionImage,
            runningExecutionTooltip,
            showChart,
            mainPanelMessage,
        };
    },
});
