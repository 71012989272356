










































































































import { defineComponent, computed, ref, PropType } from '@vue/composition-api';
import { SearchIcon } from '@vue-hero-icons/outline';
import { Scrollbar } from '@/app/components';
import { ResultsViewChart } from '.';
import { Chart, Task } from '../../types';
import ResultsViewTask from './ResultsViewTask.vue';

export default defineComponent({
    name: 'ResultsViewOptions',
    model: {
        prop: 'selectedTask',
        event: 'selected-task-changed',
    },
    props: {
        stopChartClickPropagation: {
            type: Boolean,
            default: true,
        },
        exportTasks: {
            type: Array,
            default: () => [],
        },
        selectedTask: {
            type: Object as PropType<Task>,
            default: null,
        },
        isValidWorkflow: {
            type: Boolean,
            default: true,
        },
        existingVisualisations: {
            type: Array,
            default: () => [],
        },
        readOnly: {
            type: Boolean,
            default: true,
        },
        chart: {
            type: Object as PropType<Chart>,
        },
    },
    components: { ResultsViewChart, Scrollbar, ResultsViewTask, SearchIcon },
    setup(props, { emit }) {
        const searchText = ref<string>('');
        const isBlockListExpanded = ref<boolean>(true);

        const availableCharts = computed(() => Chart.getAvailableCharts(searchText.value));

        const isShowMoreDisabled = computed(() => {
            return props.exportTasks.length < 2;
        });

        const handleSelectTask = (task: Task) => {
            isBlockListExpanded.value = false;
            emit('selected-task-changed', task);
        };

        const filteredVisualisations = computed(() => {
            if (props.readOnly) {
                if (props.existingVisualisations && props.exportTasks && props.exportTasks.length) {
                    const filteredVis: any = [];

                    props.existingVisualisations.forEach((visualisation: any) => {
                        const taskWithVisualisation = props.exportTasks.filter(
                            (task: any) => task.id === visualisation.taskId,
                        );

                        if (taskWithVisualisation && taskWithVisualisation.length) {
                            filteredVis.push(taskWithVisualisation[0]);
                        }
                    });

                    return filteredVis;
                }
            }

            return props.exportTasks;
        });
        return {
            searchText,
            availableCharts,
            isShowMoreDisabled,
            isBlockListExpanded,
            filteredVisualisations,
            handleSelectTask,
        };
    },
});
