




































import { computed, defineComponent, ref } from '@vue/composition-api';
import { RunningExecutionFooter } from '../running-execution';
import { GraphViewLibrary, GraphViewEditor } from '.';
import { Block, Task } from '../../types';

export default defineComponent({
    name: 'GraphView',
    props: {
        workflow: { type: Object, required: true },
        tasks: { type: Array, required: true },
        selectedTask: { type: String, required: false },
        stopTaskClickPropagation: { type: Boolean, default: true },
        runningExecution: { type: Object, default: null },
        pipelines: { type: Array, default: () => [] },
        pendingExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        otherRunningExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
    },
    components: { GraphViewLibrary, GraphViewEditor, RunningExecutionFooter },
    setup(props, { emit }) {
        const collapsedLibrary = ref(false);

        const filteredTasks = computed(() => props.tasks);

        const createTask = async (block: Block) => {
            emit('create-task', {
                displayName: block.name,
                blockId: block.id,
                workflowId: props.workflow.id,
                configuration: {},
            });
        };

        const showSettings = (task: Task | null) => {
            emit('show-settings', task);
        };

        return { collapsedLibrary, filteredTasks, createTask, showSettings };
    },
});
