





























































































import { defineComponent, computed, PropType } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { v4 as uuidv4 } from 'uuid';
import { requiredValidator } from '@/app/validators';
import { TwSelect } from '@/app/components';
import { useVisualisation } from '@/modules/workflow-designer/composable';
import { Chart } from '@/modules/workflow-designer/types';
import {
    AggregationFunction,
    VisualisationOptionsDescription,
    ApexChartXAxisValidValues,
} from '@/modules/workflow-designer/constants';
import ChartConfigurationInput from './ChartConfigurationInput.vue';

extend('required', requiredValidator);

export default defineComponent({
    name: 'ChartConfigurationXAxisSeriesInput',
    model: {
        prop: 'chartConfig',
        event: 'update-chart-series',
    },
    props: {
        chart: {
            type: Object as PropType<Chart>,
            required: true,
        },
        chartConfig: {
            type: Object,
            required: true,
        },
        availableSeries: {
            type: Array,
            default: () => [],
        },
        xAxisSeriesOptions: {
            type: Array,
            default: () => [],
        },
        showSeriesName: {
            type: Boolean,
            default: false,
        },
        seriesType: {
            type: String,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        validationRules: {
            type: Object,
        },
    },
    components: {
        TwSelect,
        ValidationProvider,
        ChartConfigurationInput,
    },
    setup(props, { emit }) {
        const editedChartSeries = computed({
            get: () => props.chartConfig,
            set: (updatedChartSeries: any) => {
                emit('update-chart-series', updatedChartSeries);
            },
        });

        const selectInputUuid = `field_${uuidv4()}`;

        const { addSeriesBuckets, getBucketUnitOptions } = useVisualisation();

        const aggregationFunctionsOptions = computed(() =>
            props.chart
                ? props.chart.getAggregationFunctions().map((func: AggregationFunction) => {
                      return {
                          label: func.label,
                          value: func.key,
                      };
                  })
                : [],
        );

        const handleInputChange = (value: any) => {
            const multipleFields: boolean = value.length > 1;
            const selectedOption: any = multipleFields
                ? null
                : props.availableSeries?.find((x: any) => x.label === value[0]) || null;
            const dataType: any = selectedOption?.value || null;
            const dataTypeHasChanged: boolean = editedChartSeries.value.groupBy.type !== dataType;

            // For multiple groupBy fields, data type is by default category. Buckets are not available for multiple values
            // Change buckets if data type has changed or return null for multiple groupBy fields
            editedChartSeries.value = {
                ...editedChartSeries.value,
                groupBy: {
                    ...editedChartSeries.value.groupBy,
                    fields: value,
                    type: multipleFields ? 'category' : dataType,
                    buckets: dataTypeHasChanged ? addSeriesBuckets(dataType) : editedChartSeries.value.groupBy.buckets,
                },
            };
        };

        const bucketUnitOptions = computed((): any => getBucketUnitOptions());

        // Check selected dataType in case we need to show bucketing options for case such as BAR chart.
        // For BAR chart 'Series type' must be Category, (*this would mean by default all data types that are not categorical would be filtered out),
        // but all data types can be selected as groupBy field so we need to show bucket options for such case
        const currentDataType = computed(() => {
            const dataTypeSelected = editedChartSeries.value.groupBy.type || '';
            if (ApexChartXAxisValidValues.numeric.includes(dataTypeSelected)) {
                return 'numeric';
            }
            if (ApexChartXAxisValidValues.datetime.includes(dataTypeSelected)) {
                return 'datetime';
            }
            if (ApexChartXAxisValidValues.category.includes(dataTypeSelected)) {
                return 'datetime';
            }
            return dataTypeSelected;
        });

        return {
            editedChartSeries,
            handleInputChange,
            aggregationFunctionsOptions,
            bucketUnitOptions,
            selectInputUuid,
            VisualisationOptionsDescription,
            currentDataType,
        };
    },
});
