



























































































































import { defineComponent, ref, computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { useParameter } from '@/modules/workflow-designer/composable';
import { TwInput, TwSelect } from '@/app/components';
import { InputParameter } from '@/modules/workflow-designer/types';
import ParameterValidation from '../ParameterValidation.vue';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'ModelParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        models: {
            type: Array,
            default: () => [],
        },
        isOnPremise: {
            type: Boolean,
        },
    },
    components: { ParameterValidation, TwInput, TwSelect },
    setup(props, { emit }) {
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);
        const validationRef = ref<any>(null);
        const accessClasses = {
            owned: 'bg-primary-200 text-primary-800',
            acquired: 'bg-secondary-200 text-secondary-800',
            draft: 'bg-neutral-200 text-neutral-800',
        };

        const accessSuffix = {
            owned: 'belongs to your organisation',
            acquired: 'was acquired by your organisation',
            draft: 'is draft and belongs to your organisation',
        };

        // Computes a list of possible values this string can take
        const fixedList = computed(() => {
            if (!props.isOnPremise && props.parameter.type) {
                return props.models.filter((model: any) => model.type === props.parameter.type);
            }
            return null;
        });
        const showSingleInput = computed(() => R.isNil(fixedList.value) || R.isEmpty(fixedList.value));

        const { currentValue, change, changeDate, findInitialValue } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                let resultingValue = null;
                if (!S.has('value', incomingValue) || !incomingValue?.value) {
                    // Case no existing value defined
                    if (S.has('default', props.parameter.validation)) {
                        // Case default value defined in validation information
                        resultingValue = props.parameter.validation.default;
                    } else {
                        // Case no default value defined in validation information then take default empty value
                        resultingValue = props.parameter.multiple ? [] : null;
                    }
                } else if (S.has('value', incomingValue) && incomingValue?.value && props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking multiple values
                    resultingValue = R.is(Array, incomingValue.value) ? incomingValue.value : [incomingValue.value];
                } else if (incomingValue?.value && !props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking a single value
                    if (showSingleInput.value) {
                        resultingValue = incomingValue.value;
                    } else {
                        resultingValue = incomingValue.value.id;
                    }
                }
                return resultingValue;
            },

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    if (!R.isNil(currentValue.value) && R.isEmpty(currentValue.value)) {
                        currentValue.value = findInitialValue(currentValue.value);
                    }

                    if (showSingleInput.value) {
                        emit('change', { value: currentValue.value });
                    } else {
                        const model: any = currentValue.value
                            ? props.models.find((m: any) => m.id === currentValue.value)
                            : null;
                        emit('change', {
                            value: model ? { id: model.id, assetId: model.assetId, ...model.file } : null,
                        });
                    }
                }
            },
        );

        const selectedModel = computed(() => {
            if (currentValue.value && props.models && props.models.length > 0) {
                return props.models.find((model: any) => model.id === currentValue.value);
            }
            return null;
        });

        // Set the initial value on load
        change();

        return {
            currentValue,
            selectedModel,
            fixedList,
            validationRef,
            changeDate,
            change,
            accessClasses,
            accessSuffix,
            showSingleInput,
        };
    },
});
