var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ParameterValidation',{staticClass:"flex flex-col w-full",attrs:{"rules":_vm.rules,"name":_vm.name,"changeDate":_vm.changeDate,"strict":_vm.strict},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var errorTextColour = ref.errorTextColour;
return [(!_vm.showSingleInput)?_c('div',{staticClass:"flex flex-col space-y-2"},[_c('TwSelect',{staticClass:"w-full",attrs:{"items":_vm.fixedList,"multiple":_vm.parameter.multiple,"errors":errors,"keyField":"id","labelField":"name","errorColour":errorTextColour,"disabled":_vm.readonly,"noResultsMessage":"No ML models were found that are applicable for this block","expandInPlace":""},on:{"change":_vm.change},scopedSlots:_vm._u([{key:"summary",fn:function(ref){
var fullItems = ref.fullItems;
return _vm._l((fullItems),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('span',{staticClass:"uppercase text-center px-2 py-0.5 rounded text-xs font-mediume font-semibold",class:_vm.accessClasses[item.access]},[_vm._v(" "+_vm._s(item.access)+" ")]),_c('span',[_vm._v(_vm._s(item.name))])]),(fullItems.length < index + 1)?[_vm._v(", ")]:_vm._e()],2)})}},{key:"summaryTooltip",fn:function(ref){
var fullItems = ref.fullItems;
return _vm._l((fullItems),function(item,index){return _c('div',{key:index},[_c('span',{staticClass:"flex flex-row space-x-1"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v("- This model "+_vm._s(_vm.accessSuffix[item.access])+" ")])]),(fullItems.length < index + 1)?[_vm._v(", ")]:_vm._e()],2)})}},{key:"default",fn:function(ref){
var fullItem = ref.fullItem;
return [_c('div',{staticClass:"flex flex-row space-x-2"},[_c('span',{staticClass:"text-center px-2 py-0.5 rounded text-xs font-mediume font-semibold uppercase",class:_vm.accessClasses[fullItem.access]},[_vm._v(" "+_vm._s(fullItem.access[0])+" ")]),_c('span',[_vm._v(_vm._s(fullItem.name))])])]}},{key:"tooltip",fn:function(ref){
var fullItem = ref.fullItem;
return [(fullItem)?[_c('span',{staticClass:"flex flex-row space-x-1"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(fullItem.name))]),_c('span',[_vm._v("- This model "+_vm._s(_vm.accessSuffix[fullItem.access]))])])]:_vm._e()]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}),(_vm.selectedModel)?_c('div',{staticClass:"flex flex-col p-2 space-y-3 text-xs rounded bg-primary-100"},[_c('div',{staticClass:"flex flex-col space-y-1 sm:col-span-1"},[_c('dt',{staticClass:"font-semibold text-neutral-500"},[_vm._v("Type")]),_c('dd',{staticClass:"text-primary-700"},[_vm._v(" "+_vm._s(_vm.selectedModel.name)+" ")])]),(_vm.selectedModel.featuresOrdered)?_c('div',{staticClass:"flex flex-col space-y-1 sm:col-span-1"},[_c('dt',{staticClass:"font-semibold text-neutral-500"},[_vm._v("Features")]),_c('dd',{staticClass:"leading-6"},_vm._l((_vm.selectedModel.featuresOrdered),function(feature,index){return _c('span',{key:("orderedFeature_" + index),staticClass:"py-0.5 px-1 text-white rounded bg-primary-700",class:{
                            'mr-2': index < _vm.selectedModel.featuresOrdered.length - 1,
                        }},[_vm._v(" "+_vm._s(feature)+" ")])}),0)]):_vm._e(),(_vm.selectedModel.featuresEncoded)?_c('div',{staticClass:"flex flex-col space-y-1 sm:col-span-1"},[_c('dt',{staticClass:"text-xs font-semibold text-neutral-500"},[_vm._v("Features for inverse")]),_c('dd',{staticClass:"text-xs leading-6 text-neutral-900"},_vm._l((_vm.selectedModel.featuresEncoded),function(feature,index){return _c('span',{key:feature,staticClass:"py-0.5 px-1 text-white rounded bg-primary-700 bg-primary-700",class:{
                            'mr-2': index < _vm.selectedModel.featuresEncoded.length - 1,
                        }},[_vm._v(" "+_vm._s(feature)+" ")])}),0)]):_vm._e(),(_vm.selectedModel.featuresOrdered)?_c('div',{staticClass:"text-xs italic text-primary-600"},[_c('strong',[_vm._v("Note: ")]),_vm._v("Please ensure that the input dataset contains the expected features ")]):_vm._e()]):_vm._e()],1):_c('TwInput',{staticClass:"w-full",attrs:{"type":"text","name":_vm.name,"placeholder":_vm.parameter.placeholder,"errors":errors,"errorColour":errorTextColour,"disabled":_vm.readonly},on:{"change":_vm.change},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }