





























































import { defineComponent } from '@vue/composition-api';
import { OnClickOutside, Scrollbar } from '@/app/components';

export default defineComponent({
    name: 'ValidationSummary',
    props: { errors: { type: Array, default: () => [] } },
    components: { OnClickOutside, Scrollbar },
    setup(props, { emit }) {
        const close = () => {
            emit('toggle-click-event', false);
            emit('cancel');
        };

        const goToTask = (taskId: string) => {
            close();
            emit('open-task-configuration', taskId);
        };

        return { goToTask, close };
    },
});
