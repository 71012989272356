



























































































































































































































import { computed, defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { LightningBoltIcon, PlusIcon, XIcon, CheckIcon } from '@vue-hero-icons/solid';
import { ChevronLeftIcon } from '@vue-hero-icons/outline';
import { ValidationObserver } from 'vee-validate';
import * as R from 'ramda';
import { AlertBanner, ConfirmModal, FakeFormBlock, FakeWizardTabs, Scrollbar, WizardTabs } from '@/app/components';
import { TButton } from '@/app/components/common';
import { AlertMessage, Step } from '@/modules/apollo/interfaces';
import { StatusCode, StepControl, WorkflowStatus } from '@/modules/apollo/constants';
import { AlertType } from '@/app/constants';
import FakePageTitle from '@/modules/apollo/components/FakePageTitle.vue';
import FakePageActions from '@/modules/apollo/components/FakePageActions.vue';

export default defineComponent({
    name: 'TaskConfigurationWizard',
    model: {
        prop: 'currentStep',
        event: 'change-current-step',
    },
    props: {
        currentStep: { type: Number, default: 0 },
        workflow: { type: Object, required: false },
        task: { type: Object, required: false },
        steps: { type: Array as PropType<Step[] | null>, required: false },
        loading: { type: Boolean, default: false },
        alerts: { type: Array as PropType<AlertMessage[]>, required: false },
        configuration: { type: Object, required: false },
        dirty: { type: Boolean, default: true },
    },
    components: {
        XIcon,
        TButton,
        PlusIcon,
        CheckIcon,
        Scrollbar,
        WizardTabs,
        AlertBanner,
        ConfirmModal,
        OrbitSpinner,
        FakeFormBlock,
        FakePageTitle,
        FakeWizardTabs,
        FakePageActions,
        ChevronLeftIcon,
        LightningBoltIcon,
        ValidationObserver,
    },
    setup: (props, { emit }) => {
        const formRef = ref<any>();
        const showDiscardChanges = ref<boolean>(false);
        const stepIndex = computed({
            get: () => props.currentStep,
            set: (newIndex: number) => emit('change-current-step', newIndex),
        });
        const step: Ref<Step | null> = computed((): Step | null =>
            props.steps && props.steps.length > 0 ? props.steps[stepIndex.value] : null,
        );
        const isDeprecated = computed(() => props.workflow && props.workflow.status === StatusCode.Deprecated);
        const previousStep = computed(() => {
            if (!props.steps) return null;
            for (let i = stepIndex.value - 1; i >= 0; i--) {
                if (props.steps[i].active) {
                    return i;
                }
            }
            return null;
        });

        const nextStep = computed(() => {
            if (!props.steps) return null;
            for (let i = stepIndex.value + 1; i < props.steps.length; i++) {
                if (props.steps[i].active) {
                    return i;
                }
            }
            return null;
        });

        const hasPrevious = computed(() => !R.isNil(previousStep.value));
        const hasNext = computed(() => !R.isNil(nextStep.value));

        const canGoPrevious = computed(() => {
            if (step.value && step.value.canGoPrevious && R.is(Function, step.value.canGoPrevious)) {
                return step.value.canGoPrevious(props.configuration);
            }
            return true;
        });

        const canGoNext = computed(() => {
            if (step.value && step.value.canGoNext && R.is(Function, step.value.canGoNext)) {
                return step.value.canGoNext(props.configuration);
            }
            return true;
        });

        const goPrevious = async () => {
            if (step.value && !R.isNil(previousStep.value) && canGoPrevious.value) {
                if (step.value.beforePreviousCallback && R.is(Function, step.value.beforePreviousCallback))
                    await step.value.beforePreviousCallback(props.configuration);
                stepIndex.value = previousStep.value;
            }
        };

        const goNext = async () => {
            if (step.value && !R.isNil(nextStep.value) && canGoNext.value) {
                if (step.value.beforeNextCallback && R.is(Function, step.value.beforeNextCallback)) {
                    await step.value.beforeNextCallback(props.configuration);
                }
                if (formRef.value) {
                    if (formRef.value.validate()) stepIndex.value = nextStep.value;
                    formRef.value.clear;
                } else {
                    stepIndex.value = nextStep.value;
                }
            }
        };

        return {
            step,
            formRef,
            hasNext,
            nextStep,
            stepIndex,
            canGoNext,
            AlertType,
            hasPrevious,
            StepControl,
            isDeprecated,
            previousStep,
            canGoPrevious,
            WorkflowStatus,
            showDiscardChanges,
            goNext,
            goPrevious,
        };
    },
});
