var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex col-span-1 text-white bg-white rounded shadow-sm h-14",class:[
        {
            'hover:text-purple-200 hover:bg-purple-100': _vm.colour === 'purple',
            'hover:text-blue-200 hover:bg-blue-100': _vm.colour === 'blue',
            'hover:text-green-200 hover:bg-green-100': _vm.colour === 'green',
            'hover:text-orange-200 hover:bg-orange-100': _vm.colour === 'orange',
            'opacity-75': !_vm.selected,
            'ring-primary-400 ring-4': _vm.selected,
            hidden: !_vm.isBlockListExpanded && !_vm.selected,
        } ]},[_c('div',{staticClass:"flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium rounded",class:[
            {
                'bg-purple-600 border-purple-600': _vm.colour === 'purple',
                'bg-blue-600 border-blue-600 ': _vm.colour === 'blue',
                'bg-green-600 border-green-600 ': _vm.colour === 'green',
                'bg-orange-600 border-orange-600 ': _vm.colour === 'orange',
            } ]},[_c('span',{attrs:{"title":_vm.label},domProps:{"innerHTML":_vm._s(_vm.iconHtml)}})]),_c('div',{staticClass:"flex items-center justify-between flex-1 truncate"},[_c('div',{staticClass:"flex flex-col flex-1 px-4 py-2 space-y-1 text-sm truncate"},[_c('div',{class:[
                    {
                        'text-purple-600  ': _vm.colour === 'purple',
                        'text-blue-600  ': _vm.colour === 'blue',
                        'text-green-600  ': _vm.colour === 'green',
                        'text-orange-600  ': _vm.colour === 'orange',
                    } ]},[_vm._v(" "+_vm._s(_vm.task.displayName)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }