
































































































import * as R from 'ramda';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { OnClickOutside, ConfirmModal } from '@/app/components';
import { UpdateScheduleDTO } from '../../dto';
import { ScheduleType } from '../../types';
import ScheduleEditor from './ScheduleEditor.vue';
import ViewSchedules from './ViewSchedules.vue';

export default defineComponent({
    name: 'Schedule',
    props: {
        schedules: {
            type: Array,
            default: () => [],
        },
        error: {
            type: String,
            default: null,
        },
        workflowId: {
            type: [String, Number],
            required: true,
        },
        closeEditor: {
            type: Boolean,
            required: false,
        },
        frameworkVersion: {
            type: String,
        },
    },
    components: { ScheduleEditor, ViewSchedules, OnClickOutside, ConfirmModal },
    setup(props, { emit }) {
        const openScheduleEditor = ref(false);
        const errorInScheduleEditor = ref(null);
        const scheduleToBeEdited: any = ref(null);
        const showScheduleEditCancelConfirmation = ref<boolean>(false);
        const changedSchedule: any = ref(null);
        const creationDifferencesState = ref<boolean>(false);

        const saveSchedule = (schedule: ScheduleType) => {
            emit('save', schedule);
        };
        const updateSchedule = (schedule: UpdateScheduleDTO) => {
            emit('save', { id: scheduleToBeEdited.value.id, ...schedule });
        };
        const changeScheduleStatus = (schedule: any) => {
            emit('change-status', schedule);
        };
        const deleteSchedule = (schedule: any) => {
            emit('delete-schedule', schedule.id);
        };

        watch(
            () => props.error,
            (error: any) => {
                errorInScheduleEditor.value = R.clone(error);
            },
        );

        watch(
            () => props.closeEditor,
            (close: any) => {
                if (close) {
                    openScheduleEditor.value = false;
                    scheduleToBeEdited.value = null;
                }
            },
        );

        const differencesExist = computed(() => {
            if (scheduleToBeEdited.value && changedSchedule.value) {
                const scheduleToCompare = {
                    ...scheduleToBeEdited.value,
                    startDate: new Date(scheduleToBeEdited.value.startDate),
                    endDate: new Date(scheduleToBeEdited.value.endDate),
                };
                const checkDifferences = JSON.stringify(scheduleToCompare) !== JSON.stringify(changedSchedule.value);
                if (checkDifferences) {
                    emit('toggle-click-event', true);
                }
                // else {
                //     emit('toggle-click-event', false);
                // }
                return checkDifferences;
            }
            return false;
        });

        const justCreated = ref(true);
        const close = (confirm = true) => {
            if ((differencesExist.value || creationDifferencesState.value) && confirm) {
                showScheduleEditCancelConfirmation.value = true;
            } else if (!justCreated.value) {
                emit('toggle-click-event', false);
                showScheduleEditCancelConfirmation.value = false;

                if (openScheduleEditor.value && (differencesExist.value || creationDifferencesState.value)) {
                    openScheduleEditor.value = false;
                    creationDifferencesState.value = false;
                    scheduleToBeEdited.value = null;
                    emit('reset-error');
                } else {
                    emit('cancel');
                }
            }
            justCreated.value = false;
        };

        const setChangedSchedule = (schedule: any) => {
            changedSchedule.value = schedule;
            emit('changed-schedule', differencesExist.value);
        };

        const addSchedule = () => {
            openScheduleEditor.value = true;
            scheduleToBeEdited.value = null;
        };
        const editSchedule = (schedule: any) => {
            if (scheduleToBeEdited.value && scheduleToBeEdited.value.id !== schedule.id) {
                emit('reset-error');
            }
            scheduleToBeEdited.value = schedule;
            openScheduleEditor.value = true;
        };
        const closeScheduleEditor = () => {
            close();
        };

        const closeScheduleEditorAfterSave = () => {
            openScheduleEditor.value = false;
            scheduleToBeEdited.value = null;
            emit('reset-error');
        };

        const setCreationDifferencesState = (state: boolean) => {
            creationDifferencesState.value = state;
        };

        return {
            openScheduleEditor,
            saveSchedule,
            changeScheduleStatus,
            deleteSchedule,
            editSchedule,
            scheduleToBeEdited,
            closeScheduleEditor,
            addSchedule,
            updateSchedule,
            errorInScheduleEditor,
            emit,
            differencesExist,
            showScheduleEditCancelConfirmation,
            setChangedSchedule,
            close,
            setCreationDifferencesState,
            closeScheduleEditorAfterSave,
        };
    },
});
