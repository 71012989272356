import * as R from 'ramda';
import { RadarIcon } from '../../components/results-view/assets/icons';
import {
    AggregationFunction,
    ApexChartColors,
    ApexChartDataTypes,
    ApexChartType,
    ApexChartXAxisValidValues,
    ChartConfigType,
} from '../../constants';
import { Chart } from './chart';

export class Radar extends Chart {
    protected chartType: ApexChartType = ApexChartType.Radar;

    protected description = 'The radar chart indicates the relative influence of different numerical parameters.';

    protected xAxisTypes = { category: ApexChartXAxisValidValues.category };

    protected chartConfigurationSections: ChartConfigType[] = [ChartConfigType.General, ChartConfigType.Series];

    protected validDataTypes: string[] = [ApexChartDataTypes.DATETIME];

    protected aggregationMaxFields = 4;

    protected aggregationFunctions: AggregationFunction[] = [AggregationFunction.COUNT, AggregationFunction.SUM];

    protected icon: any = RadarIcon;

    // Radar needs its own configuration schema
    configuration(title = 'Title'): any {
        return R.clone({
            ...Chart.defaultMarkers,
            ...Chart.defaultConfiguration(R.isNil(title) ? 'Title' : title, this.description),
            ...Chart.defaultLegend,
            ...Chart.defaultDataLabels,
            ...Chart.defaultChartOption,
            yaxis: {
                decimalsInFloat: 2,
                show: true,
                labels: {
                    show: true,
                },
                tooltip: {
                    enabled: false,
                },
                crosshairs: {
                    show: false,
                },
            },
            xaxis: {
                type: 'category',
            },
            plotOptions: {
                size: 1000,
                offsetX: 0,
                offsetY: 0,
                polygons: {
                    strokeColors: ApexChartColors.Polygons,
                    strokeWidth: 4,
                    connectorColors: ApexChartColors.Polygons,
                },
            },
        });
    }

    // eslint-disable-next-line class-methods-use-this
    yAxisSeriesOptions(structure: any): any[] {
        const temp: any[] = [];
        Object.keys(structure).forEach((key) => {
            // PieFamily can visualise all data types
            temp.push({ label: key, value: structure[key] });
        });

        return temp;
    }
}

Chart.register(new Radar());
