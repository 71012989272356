import { computed, Ref } from '@vue/composition-api';
import { S } from '@/app/utilities';
import Papa from 'papaparse';
import * as R from 'ramda';
import { parseString } from 'xml2js';
import { useFileHarvesterHelper } from './file-harvester-helper.composable';

export function useFileHarvester(fileType: Ref<string>) {
    const { reduceSampleValues, getAllPaths, calculateFilteredPaths, checkEmptyJSON } = useFileHarvesterHelper();

    /***
     * Validates CSV file and sets validation percentage ref
     *
     * @param file
     * @param validationPercentage
     */
    const validateCSV = (file: File, validationPercentage: Ref<number | null>): Promise<void> => {
        const CHUNK_SIZE = 1024 * 1024 * 5; // 5MB
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        let chunk = 0;

        return new Promise<void>((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                preview: null,
                chunkSize: CHUNK_SIZE,
                skipEmptyLines: true,
                dynamicTyping: false,
                chunk: (results: any) => {
                    chunk += 1;
                    validationPercentage.value = Math.ceil((chunk / totalChunks) * 100);
                    // check if there are any duplicate headers
                    const headers = results.meta.fields;
                    const uniqueHeaders = new Set(headers); // keep only the unique headers
                    const errors = [];
                    if (headers.length !== uniqueHeaders.size)
                        errors.push('Duplicate fields have been detected in the file!');
                    if (results.errors.length > 0) errors.push('Invalid file format!');
                    if (errors.length > 0) {
                        reject(errors);
                    }
                    if (chunk === totalChunks) {
                        resolve();
                    }
                },
            } as any);
        });
    };

    /***
     * Parses csv and returns the data as json, the metadata and whether there was any cropping
     *
     * @param file - File to be parsed
     * @param records - Max number of records in arrays
     */
    const parseCSV = (
        file: File,
        records?: number,
    ): Promise<{ data: any; meta: { fields: string[] }; cropped: boolean }> => {
        return new Promise<any>((resolve) => {
            Papa.parse(file, {
                header: true,
                preview: records || null,
                chunkSize: null,
                skipEmptyLines: true,
                dynamicTyping: true,
                transform: (value: any) => value.trim(),
                complete: (results: any) => {
                    resolve({ data: results.data, meta: results.meta, cropped: !R.isNil(records) });
                },
            } as any);
        });
    };

    /***
     * Parses json and returns the data as json, the fields and whether there was any cropping
     *
     * @param file - File to be parsed
     * @param records - Max number of records in arrays
     * @param sampleCroppedAt - If this is not a sample, where was the sample cropped at
     */
    const parseJSON = async (
        file: any,
        records?: number,
        sampleCroppedAt?: number | null,
    ): Promise<{ data: any; meta: { fields: string[] }; cropped: boolean }> => {
        const data = await file.text();
        return new Promise((resolve: any, reject: any) => {
            let json;
            try {
                json = JSON.parse(data);
            } catch (e) {
                reject(['Invalid JSON format!']);
            }
            if (checkEmptyJSON(json)) {
                reject(['Empty JSON file!']);
            }
            let reducedJsonForFields = { ...json };
            if (sampleCroppedAt) {
                const { obj } = reduceSampleValues(json, sampleCroppedAt);
                reducedJsonForFields = obj;
            }
            const { obj, cropped } = R.isNil(records)
                ? { obj: json, cropped: false }
                : reduceSampleValues(json, records);
            resolve({
                data: obj,
                cropped,
                meta: {
                    fields: [...new Set(getAllPaths(reducedJsonForFields, '', ''))],
                },
            });
        });
    };

    /***
     * Parses xml and returns the data as json, the fields and whether there was any cropping
     *
     * @param file - File to be parsed
     * @param records - Max number of records in arrays
     * @param sampleCroppedAt - If this is not a sample, where was the sample cropped at
     */
    const parseXML = async (
        file: any,
        records?: number,
        sampleCroppedAt?: number | null,
    ): Promise<{ data: any; meta: { fields: string[] }; cropped: boolean }> => {
        const data = await file.text();
        return new Promise((resolve: any, reject: any) => {
            parseString(data, (err, result) => {
                if (err || !result) {
                    reject(['Invalid XML format!']);
                }
                let parsedXml;
                if (R.type(result) === 'Object') {
                    parsedXml = [result];
                } else {
                    parsedXml = result;
                }
                let reducedJson = { ...parsedXml };
                if (sampleCroppedAt) {
                    const { obj } = reduceSampleValues(parsedXml, sampleCroppedAt);
                    reducedJson = obj;
                }
                const { obj, cropped } = R.isNil(records)
                    ? { obj: parsedXml, cropped: false }
                    : reduceSampleValues(parsedXml, records);
                resolve({
                    data: obj,
                    cropped,
                    meta: { fields: [...new Set(getAllPaths(reducedJson, '', ''))] },
                });
            });
        });
    };

    /**
     * Compares the csv headers (columns) of the sample file with the csv headers of the full file to be uploaded.
     * If a header/ column in either csv file (sample or full file) is empty, then in the error displayed to the user,
     * it is written as 'empty column header'
     *
     * @param fileHeaders
     * @param sampleHeaders
     */
    const calculateInconsistenciesCSV = async (fileHeaders: string[], sampleHeaders: string[]): Promise<void> => {
        return new Promise<void>((resolve: any, reject: any) => {
            const errors: string[] = [];

            errors.push(
                ...sampleHeaders
                    .filter((h: any) => fileHeaders.indexOf(h) === -1)
                    .map((field: string) => `Column "${field}" exists in the sample but is missing in the file`),
            );
            errors.push(
                ...fileHeaders
                    .filter((h: any) => sampleHeaders.indexOf(h) === -1)
                    .map((field: string) => `Column "${field}" exists in the file but not in the sample`),
            );
            errors.push(
                ...fileHeaders
                    .filter((header: any, idx: number) => fileHeaders.indexOf(header) !== idx)
                    .map((field: string) => `Column "${field}" exists more than once in the uploaded file`),
            );
            if (errors.length > 0) {
                reject(errors);
            }
            resolve();
        });
    };

    /**
     * Calculates if there are any errors between the file and sample
     *
     * @param fields
     * @param sampleFields
     */
    const calculateInconsistenciesJson = async (fields: string[], sampleFields: string[]): Promise<void> => {
        const filteredSamplePaths = await calculateFilteredPaths(sampleFields);
        const filteredFilePaths = await calculateFilteredPaths(fields);
        return new Promise<void>((resolve: any, reject: any) => {
            const errors: string[] = [];
            // remove any paths which are identical

            let necessaryFields: string[] = [];
            let extraInvalidFields: string[] = [];

            // calculate which fields exist in the sample, but not in the full file to be uploaded
            filteredSamplePaths.forEach((sp: any) => {
                if (!filteredFilePaths.find((fp: any) => sp.toString() === fp.toString())) {
                    necessaryFields.push(sp);
                }
            });

            // calculate which fields exist in the full file to be uploaded, but not in the sample
            filteredFilePaths.forEach((fp: any) => {
                if (!filteredSamplePaths.find((sp: any) => sp.toString() === fp.toString())) {
                    extraInvalidFields.push(fp);
                }
            });

            necessaryFields = [...new Set(necessaryFields)];
            extraInvalidFields = [...new Set(extraInvalidFields)];
            if (necessaryFields.length > 0 || extraInvalidFields.length > 0) {
                errors.push(`Inconsistencies detected between the Sample and the File`);
            }

            // can show explicit errors if we want
            // errors.push(
            //     ...necessaryFields.map(
            //         (field: string) => `Field "${field}" exists in the sample but is missing in the file`,
            //     ),
            // );
            //
            // errors.push(
            //     ...extraInvalidFields.map(
            //         (field: string) => `Field "${field}" exists in the file but not in the sample`,
            //     ),
            // );

            if (errors.length > 0) {
                reject(errors);
            }
            resolve();
        });
    };

    // the supported file types
    const fileTypes: Record<
        string,
        {
            extensions: string[];
            path?: string;
            sampleCropLimit?: number;
            validate?: any;
            compareToSample?: any;
            warnIfCropped?: boolean;
            renderAs?: string;
        }
    > = {
        csv: {
            extensions: ['csv', 'tsv'],
            path: 'inputs/csv',
            sampleCropLimit: 50,
            warnIfCropped: false,
            renderAs: 'csv',
            validate: async (file: File, validationPercentage: Ref<number | null>, records?: number) => {
                validationPercentage.value = 0;
                if (!file) return;
                return new Promise((resolve: any, reject: any) => {
                    validateCSV(file, validationPercentage)
                        .then(() => resolve(parseCSV(file, records)))
                        .catch((errors: string[]) => reject(errors));
                });
            },
            compareToSample: async (file: any, meta: { fields: string[] }, sampleFields: string[]) =>
                await calculateInconsistenciesCSV(meta.fields, sampleFields),
        },
        json: {
            extensions: ['json'],
            path: 'inputs/json',
            sampleCropLimit: 25,
            warnIfCropped: true,
            renderAs: 'json',
            validate: async (
                file: File,
                validationPercentage: Ref<number | null>,
                records?: number,
                isSampleCropped?: boolean,
            ) => {
                if (!file) return;
                return await parseJSON(file, records, isSampleCropped ? 25 : null);
            },
            compareToSample: async (file: any, meta: { fields: string[] }, sampleFields: string[]) =>
                await calculateInconsistenciesJson(meta.fields, sampleFields),
        },
        xml: {
            extensions: ['xml'],
            path: 'inputs/xml',
            sampleCropLimit: 25,
            warnIfCropped: true,
            renderAs: 'json',
            validate: async (
                file: File,
                validationPercentage: Ref<number | null>,
                records?: number,
                isSampleCropped?: boolean,
            ) => {
                if (!file) return;
                return await parseXML(file, records, isSampleCropped ? 25 : null);
            },
            compareToSample: async (file: any, meta: { fields: string[] }, sampleFields: string[]) =>
                await calculateInconsistenciesJson(meta.fields, sampleFields),
        },
        other: {
            extensions: [],
        },
    };

    const fileTypeConfiguration = computed(() =>
        fileType.value && S.has(fileType.value, fileTypes) ? fileTypes[fileType.value] : undefined,
    );

    const acceptedFiles = computed(() => (fileTypeConfiguration.value ? fileTypeConfiguration.value.extensions : []));
    const fileTypePath = computed(() => (fileTypeConfiguration.value ? fileTypeConfiguration.value.path : null));
    const fileTypeCropSize = computed(() =>
        fileTypeConfiguration.value ? fileTypeConfiguration.value?.sampleCropLimit : undefined,
    );
    const warnIfCropped = computed(() =>
        fileTypeConfiguration.value ? fileTypeConfiguration.value?.warnIfCropped : false,
    );

    const renderAs = computed(() => (fileTypeConfiguration.value ? fileTypeConfiguration.value?.renderAs : undefined));

    const validate = (
        file: File,
        validationPercentage: Ref<number | null>,
        sampleFields?: string[] | null,
        records?: number,
        isSampleCropped?: boolean,
    ): Promise<any> => {
        if (fileTypeConfiguration.value) {
            return new Promise<any>((resolve: any, reject: any) => {
                if (fileTypeConfiguration.value) {
                    if (!fileTypeConfiguration.value.validate) resolve({ data: file, cropped: false });
                    fileTypeConfiguration.value
                        .validate(file, validationPercentage, records, isSampleCropped)
                        .then(
                            ({ data, meta, cropped }: { data: any; meta: { fields: string[] }; cropped?: boolean }) => {
                                if (fileTypeConfiguration.value && sampleFields) {
                                    resolve(fileTypeConfiguration.value.compareToSample(data, meta, sampleFields));
                                }
                                resolve({ data, meta, cropped });
                            },
                        )
                        .catch((errors: string[]) => reject(errors));
                }
            });
        }
        throw Error(`FileType ${fileType.value} is not supported`);
    };

    return { renderAs, acceptedFiles, fileTypePath, fileTypeCropSize, warnIfCropped, validate };
}
