import * as R from 'ramda';
import { computed, Ref } from '@vue/composition-api';
import { InputParameter } from '../../types';
import { S } from '@/app/utilities';

export function useLogicalParameter(parameter: Ref<InputParameter>, val: Ref<any>) {
    const viewClause = (clause: any) => {
        return parameter.value.parameters
            .reduce((acc: string[], param: InputParameter) => {
                if (
                    S.has(param.name, clause) &&
                    !R.isNil(clause[param.name]) &&
                    !R.isEmpty(clause[param.name]) &&
                    S.has('value', clause[param.name]) &&
                    !R.isNil(clause[param.name].value) &&
                    !R.isEmpty(clause[param.name].value)
                ) {
                    let text = clause[param.name].value;
                    if (param.validation && S.has('values', param.validation)) {
                        for (
                            let i = 0;
                            param.validation && param.validation?.values && i < param.validation?.values.length;
                            i++
                        ) {
                            const item = param.validation?.values[i];
                            if (R.is(Object, item) && S.has('text', item) && item.value === clause[param.name].value) {
                                text = item.text;
                            }
                        }
                    }
                    acc.push(text);
                }
                return acc;
            }, [])
            .join(' ');
    };

    const newConditionTemplate = computed(() => {
        return parameter.value.parameters.reduce((acc: any, param: InputParameter) => {
            const newAcc = { ...acc };
            newAcc[param.name] = null;
            return newAcc;
        }, {});
    });
    const hasValue = computed(
        () =>
            !R.isNil(val.value) &&
            !R.isEmpty(val.value) &&
            !R.isNil(val.value.clauses) &&
            !R.isEmpty(val.value.clauses),
    );

    const newGroupTemplate = { logical: 'AND', clauses: [] };

    const updateGroups = (query: any, newGroups: any[]) => {
        return {
            ...query,
            clauses: [...query.clauses, ...newGroups],
        };
    };

    const updateConditions = (query: any, newConditions: any[], replace = false) => {
        if (replace) {
            return {
                ...query,
                clauses: newConditions,
            };
        }
        return {
            ...query,
            clauses: [...query.clauses, ...newConditions],
        };
    };

    const isConditionCalculator = (condition: any) => {
        return !S.has('logical', condition) && !S.has('clauses', condition);
    };

    const isGroupCalculator = (group: any) => {
        return S.has('logical', group) && S.has('clauses', group);
    };

    const snakeCase = (str: string) => {
        const trimmedName = str.split('_').join(' ');

        return trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1);
    };

    const preprocessCondition = (condition: any) => {
        return condition;
    };

    const conditionsExtractor = (query: any) => {
        return S.has('clauses', query) && S.has('logical', query) && !R.isNil(query.clauses) ? query.clauses : [];
    };

    const themeCalculator = (query: any) => {
        switch (query.logical) {
            case 'AND':
                return 'purple';
            case 'OR':
                return 'teal';
            default:
                return null;
        }
    };

    const complexParameterIncomingProcessor = (incomingValue: any) => {
        return incomingValue;
    };

    const complexParameterOutgoingProcessor = (outgoingValue: any) => {
        return outgoingValue;
    };

    return {
        newConditionTemplate,
        updateConditions,
        updateGroups,
        preprocessCondition,
        isConditionCalculator,
        isGroupCalculator,
        snakeCase,
        newGroupTemplate,
        conditionsExtractor,
        themeCalculator,
        complexParameterIncomingProcessor,
        complexParameterOutgoingProcessor,
        hasValue,
        viewClause,
    };
}
