import { Loader } from '@/modules/apollo/views';

export const OutputMethods: { name: string; description: string; blockId: string; component?: any }[] = [
    {
        name: 'Loader',
        description: 'Loader description',
        blockId: 'dc.Loader',
        component: Loader,
    },
];
