import * as R from 'ramda';
import { computed, ref, Ref } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { useErrors, useQuery, useResult } from '@/app/composable';
import { S } from '@/app/utilities';
import GET_BLOCKS from '../graphql/blocks.graphql';
import { Block } from '../types';

const filterBlock = (block: any, filter: string, framework: string, platform: string) => {
    if (block.category === filter) {
        if (
            ((block.frameworks instanceof Array && block.frameworks.includes(framework)) ||
                block.frameworks === framework) &&
            (!S.has('platforms', block) ||
                (block.platforms instanceof Array && block.platforms.includes(platform)) ||
                block.platforms === platform)
        ) {
            return true;
        }
    }

    return false;
};
const byType = R.groupBy((block: any) => {
    return block.type ? block.type : 'other';
});

const sortByType = R.sortWith([R.ascend(R.prop('type'))]);
const sortByName = R.sortWith([R.ascend(R.prop('name'))]);

export function useBlocks(searchText: Ref<string>, activeFilter: Ref<string>, framework: string, platform: string) {
    const { checkGQLAuthentication } = useErrors();
    const apolloRunner = useQuery(GET_BLOCKS, {}, { fetchPolicy: 'no-cache' });
    const axiosRunner = useAxios();

    const blocks = ref<Block[]>([]);
    apolloRunner.onResult(() => {
        blocks.value = useResult(apolloRunner.result, null, (data: any) => data.blocks).value as Block[];
    });
    apolloRunner.onError(checkGQLAuthentication);

    const visibleBlocks = computed(() => {
        let blocksClone = R.clone(blocks.value);

        // blocksClone = R.filter(
        //     (b) => b.category === activeFilter.value && b.frameworks.includes(framework),
        //     blocksClone,
        // );

        blocksClone = R.filter((b) => filterBlock(b, activeFilter.value, framework, platform), blocksClone);
        blocksClone = R.filter((b: Block) => !b.id.startsWith('dc.'), blocksClone);

        if (searchText.value.trim() !== '') {
            blocksClone = (blocksClone as Block[]).filter((block: Block) =>
                R.toLower(block.name).includes(R.toLower(searchText.value)),
            );
        }

        blocksClone = sortByName(sortByType(blocksClone as { name: string; type: string }[]) as Block[]) as Block[];
        let groupedBlocks = byType(blocksClone);

        /* if a block has no type, it is grouped in the 'other' type and
         * it is put last in the list if it occurs with other types
         */
        if ('other' in groupedBlocks && Object.keys(groupedBlocks).length > 1) {
            const keepOtherBlocks = R.clone(groupedBlocks.other);
            delete groupedBlocks.other;
            groupedBlocks = { ...groupedBlocks, other: keepOtherBlocks };
        }

        return groupedBlocks;
    });

    const errors = computed(() => {
        const errorsList = [];
        if (axiosRunner.error.value) {
            errorsList.push(axiosRunner.error.value.message);
        }

        if (apolloRunner.error.value) {
            errorsList.push(apolloRunner.error.value.message);
        }

        return errorsList;
    });

    const loading = computed(() => axiosRunner.loading.value || apolloRunner.loading.value);

    const { refetch } = apolloRunner;
    const { exec } = axiosRunner;

    return { blocks, visibleBlocks, errors, loading, refetch, exec };
}
