












































































































































import * as R from 'ramda';
import { defineComponent, ref, computed } from '@vue/composition-api';
import Scrollbar from '@/app/components/Scrollbar.vue';
import { SvgImage } from '@/app/components';
import TableViewOverview from './TableViewOverview.vue';
import TableViewTable from './TableViewTable.vue';
import { RunningExecutionFooter } from '../running-execution';
import { Task } from '../../types';
import { ExecutionTypeWrapper } from '../../constants';

export default defineComponent({
    name: 'TableView',
    props: {
        workflow: {
            type: Object,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
        taskMap: {
            type: Map,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        stopTaskClickPropagation: {
            type: Boolean,
            default: true,
        },
        columnsPerTask: {
            type: Object,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        pendingExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        otherRunningExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
    },
    components: { Scrollbar, TableViewOverview, TableViewTable, SvgImage, RunningExecutionFooter },
    setup(props, { emit }) {
        const isCollapsed = ref<boolean>(false);
        const overviewIconHovered = ref<boolean>(false);
        const selected = ref<Task | null>(null);

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            return null;
        });

        const selectedTask = computed(() =>
            selected.value ? props.tasks.find((task: any) => task.id === selected.value?.id) : null,
        );

        const showSettings = (task: Task) => {
            emit('show-settings', task);
        };

        const testRun = () => {
            emit('test-run', selected.value);
        };

        const isValid = (task: Task) => !props.invalidTaskIds.includes(task.id);

        return {
            selected,
            selectedTask,
            isCollapsed,
            overviewIconHovered,
            testRun,
            showSettings,
            runningExecutionTooltip,
            isValid,
        };
    },
});
