


















































































































































import { defineComponent, ref, computed, onMounted, watch, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ConfirmModal } from '@/app/components';
import { ResultsViewMain, ResultsViewOptions } from '.';
import { BlockCategory, VisualisationType, WarningMessages } from '../../constants';
import { Chart, Task, ChartConfig } from '../../types';
import ChartConfiguration from './chart-configuration/ChartConfiguration.vue';
import { useVisualisation } from '../../composable';

const OFFSET = {
    height: 250,
    width: 150,
};

export default defineComponent({
    name: 'ResultsView',
    props: {
        workflow: {
            type: Object,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        stopChartClickPropagation: {
            type: Boolean,
            default: true,
        },
        visualisationsConfigs: {
            type: Array,
            default: () => [],
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ResultsViewMain,
        ResultsViewOptions,
        ChartConfiguration,
        ConfirmModal,
    },
    setup(props, { emit, root }) {
        const selectedTask = ref<Task | null>(null);
        const isCollapsed = ref<boolean>(false);
        const overviewIconHovered = ref<boolean>(false);
        const mainViewWrapper = ref<any>(null);
        const availableSeriesRef = ref<any>(null);
        const chart = ref<Chart>();
        const chartConfiguration = ref<ChartConfig | null>(null);
        const lastSavedChartConfiguration = ref<ChartConfig | null>(null);
        const visualisations = ref<any>(R.clone(props.visualisationsConfigs));
        const showInvalidConfigurationWarning = ref<boolean>(false);
        const height = ref<any>(600);
        const width = ref<any>(600);
        const isExecutionRunning = ref<boolean>(false);
        const invalidConfiguration = ref<boolean>(false);

        const {
            createVisualisation,
            updateVisualisation,
            deleteVisualisation,
            loading,
            isVisualisationConfigValid,
            seriesStructure,
            getRetrievalConfig,
            getSeriesOptions,
        } = useVisualisation(props.tasks, selectedTask, chartConfiguration as Ref<ChartConfig | null>);

        const upstreamTask: any = computed(() => {
            return props.tasks.find((task: any) => task.id === selectedTask.value?.upstreamTaskIds[0]);
        });

        const assetId = computed(() => {
            return selectedTask.value?.block.id === 'ui.StoreCloudResult'
                ? selectedTask.value.configuration.assetId.value
                : null;
        });

        const exportTasks = computed(() => {
            // Visualisation is available only for StoreCloudResult block
            return props.tasks.filter(
                (task: any) =>
                    task.block.category === BlockCategory.Output &&
                    task.block.id === 'ui.StoreCloudResult' &&
                    task.upstreamTaskIds.length,
            );
        });

        const warningMessage = computed(() => {
            if (R.isEmpty(upstreamTask.value)) return null;
            const executions = upstreamTask.value?.executions;
            if (executions && executions.length) {
                switch (executions[0].type) {
                    case 'dry':
                        return WarningMessages.DRY_RUN;
                    case 'test':
                        return executions[0]?.result?.sample.length ? null : WarningMessages.NO_TEST_RUN;
                    default:
                        return null;
                }
            } else if (chart.value) {
                return WarningMessages.NO_EXECUTIONS;
            } else return null;
        });

        const showConfiguration = computed(() => {
            return !isExecutionRunning.value && !warningMessage.value && !!chartConfiguration.value;
        });

        const handleChangeType = (newChart: Chart | null) => {
            // Once visualisations are limited one per task, selecting other chart type creates a default config without visualisation Id. It is assigned below
            if (newChart) {
                chart.value = newChart;
                const seriesOptions = getSeriesOptions(
                    chart.value.hasSeriesConfigurationSection(),
                    chart.value.getChartType(),
                );
                chartConfiguration.value = new ChartConfig(
                    newChart,
                    selectedTask.value?.displayName,
                    null,
                    chartConfiguration.value?.visualisationId,
                    seriesOptions,
                );
                lastSavedChartConfiguration.value = new ChartConfig(
                    newChart,
                    selectedTask.value?.displayName,
                    null,
                    chartConfiguration.value?.visualisationId,
                    seriesOptions,
                );
            }
        };

        const saveVisualisation = () => {
            if (!chartConfiguration.value) return;
            const existingVisualisation = visualisations.value.find(
                (visualisation: any) => visualisation.taskId === selectedTask.value?.id,
            );
            const retrievalConfig = getRetrievalConfig(chartConfiguration.value, assetId.value || undefined);
            const configuration = {
                title: chartConfiguration.value.configuration.title.text,
                subtitle: chartConfiguration.value.configuration.subtitle.text,
                type: VisualisationType.Chart,
                workflowId: props.workflow.id,
                taskId: selectedTask.value?.id,
                configuration: {
                    options: chartConfiguration.value.configuration,
                    title: chartConfiguration.value.title,
                    type: chartConfiguration.value.chart.getChartType(),
                },
                assetId: assetId.value ? assetId.value : null,
                retrieval: retrievalConfig,
            };
            if (existingVisualisation === undefined) {
                createVisualisation(configuration)
                    .then((res: any) => {
                        visualisations.value.push(res.data);
                        emit('visualisation-created');
                        (root as any).$toastr.s(`Visualisation successfully saved!`, 'Success');
                        if (chartConfiguration.value) {
                            lastSavedChartConfiguration.value = new ChartConfig(
                                chartConfiguration.value.chart as Chart,
                                selectedTask.value?.displayName,
                                chartConfiguration.value.configuration,
                                chartConfiguration.value.visualisationId,
                                chartConfiguration.value.seriesOptions,
                            );
                        }
                    })
                    .catch((err: { response: { data: { message: string } } }) => {
                        (root as any).$toastr.e(err.response.data.message, 'Error');
                    });
            } else {
                updateVisualisation({
                    ...existingVisualisation,
                    ...configuration,
                })
                    .then((res: any) => {
                        visualisations.value = R.clone(visualisations.value).map((vis: any) => {
                            if (vis.id === res.data.id) {
                                return res.data;
                            }
                            return vis;
                        });
                        emit('visualisation-updated');
                        (root as any).$toastr.s(`Visualisation successfully updated!`, 'Success');
                        // Updates last saved config after saving (create/update)
                        if (chart.value && chartConfiguration.value?.visualisationId) {
                            lastSavedChartConfiguration.value = new ChartConfig(
                                chartConfiguration.value.chart as Chart,
                                selectedTask.value?.displayName,
                                chartConfiguration.value.configuration,
                                chartConfiguration.value.visualisationId,
                                chartConfiguration.value.seriesOptions,
                            );
                        }
                    })
                    .catch((err: { response: { data: { message: string } } }) => {
                        (root as any).$toastr.e(err.response.data.message, 'Error');
                    });
            }
        };

        const updateChartConfiguration = (
            configuration: any,
            seriesOptions: { groupBy: any; series: []; filters: any; label: '' },
        ) => {
            if (chartConfiguration.value) {
                chartConfiguration.value = new ChartConfig(
                    chartConfiguration.value.chart as Chart,
                    null,
                    configuration,
                    chartConfiguration.value.visualisationId,
                    seriesOptions,
                );
            }
        };

        const resetChartConfig = () => {
            if (lastSavedChartConfiguration.value) {
                chartConfiguration.value = new ChartConfig(
                    lastSavedChartConfiguration.value.chart as Chart,
                    selectedTask.value?.displayName,
                    lastSavedChartConfiguration.value.configuration,
                    lastSavedChartConfiguration.value.visualisationId,
                    lastSavedChartConfiguration.value.seriesOptions,
                );
            }
        };

        const deleteChartConfig = () => {
            if (!chartConfiguration || !chartConfiguration.value?.visualisationId) return;
            deleteVisualisation(chartConfiguration.value?.visualisationId)
                .then(() => {
                    visualisations.value = R.clone(visualisations.value).filter(
                        (vis: any) => vis.id !== chartConfiguration.value?.visualisationId,
                    );
                    emit('visualisation-deleted');

                    // Reset config
                    chartConfiguration.value = null;
                    lastSavedChartConfiguration.value = null;
                    // Hide warning
                    showInvalidConfigurationWarning.value = false;
                    // Show success message
                    (root as any).$toastr.s(`Visualisation successfully deleted!`, 'Success');
                })
                .catch((err: { response: { data: { message: string } } }) => {
                    // Hide warning
                    showInvalidConfigurationWarning.value = false;
                    // Show error message
                    (root as any).$toastr.e(err.response.data.message, 'Error');
                });
        };

        const testRun = () => {
            emit('test-run', upstreamTask.value);
        };

        const handleRunningExecutionStatus = (isRunning: boolean) => {
            isExecutionRunning.value = isRunning;
        };

        const handleSelectedTaskChanged = (newTask: Task): void => {
            const storedConfig = visualisations.value.find(
                (visualisation: any) => visualisation.taskId === newTask?.id,
            );
            if (storedConfig !== undefined) {
                const newChart = Chart.findChart(storedConfig.configuration.type);
                chart.value = newChart;
                const dataType = storedConfig.retrieval.groupBy?.type;
                chartConfiguration.value = new ChartConfig(
                    newChart,
                    storedConfig.title,
                    storedConfig.configuration.options,
                    storedConfig.id,
                    R.clone({
                        groupBy: { ...storedConfig.retrieval.groupBy, type: dataType || '' },
                        series: storedConfig.retrieval.series,
                        filters: storedConfig.retrieval.filters,
                        label: storedConfig.retrieval.label || '',
                        pageSize: storedConfig.retrieval.pageSize,
                    }),
                );
                lastSavedChartConfiguration.value = new ChartConfig(
                    chartConfiguration.value.chart as Chart,
                    selectedTask.value?.displayName,
                    chartConfiguration.value.configuration,
                    chartConfiguration.value.visualisationId,
                    chartConfiguration.value.seriesOptions,
                );
                if (!isVisualisationConfigValid.value) {
                    showInvalidConfigurationWarning.value = true;
                }
            } else {
                // Reset config if output block without visualisation config is selected
                chartConfiguration.value = null;
                lastSavedChartConfiguration.value = null;
                chart.value = undefined;
            }
            availableSeriesRef.value = seriesStructure.value;
        };

        // Handle resize
        const handleResize = async () => {
            const newHeight = (await mainViewWrapper.value?.clientHeight) - OFFSET.height;
            const newWidth = (await mainViewWrapper.value?.clientWidth) - OFFSET.width;
            height.value = newHeight;
            width.value = newWidth;
        };

        window.addEventListener('resize', handleResize);
        root.$once('hook:beforeDestroy', () => {
            window.removeEventListener('resize', handleResize);
        });

        const handleChangedValidation = (invalid: boolean): void => {
            invalidConfiguration.value = invalid;
        };

        watch(
            () => showConfiguration.value,
            () => {
                handleResize();
            },
        );

        onMounted(() => {
            handleResize();
        });

        return {
            isCollapsed,
            overviewIconHovered,
            mainViewWrapper,
            height,
            width,
            handleChangeType,
            exportTasks,
            selectedTask,
            availableSeriesRef,
            saveVisualisation,
            chartConfiguration,
            upstreamTask,
            loading,
            warningMessage,
            isVisualisationConfigValid,
            testRun,
            showInvalidConfigurationWarning,
            resetChartConfig,
            deleteChartConfig,
            handleRunningExecutionStatus,
            isExecutionRunning,
            visualisations,
            lastSavedChartConfiguration,
            updateChartConfiguration,
            chart,
            handleSelectedTaskChanged,
            handleChangedValidation,
            invalidConfiguration,
            showConfiguration,
        };
    },
});
