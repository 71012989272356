import { ValidatorUtils } from './utils';

export const decimalValidator = {
    validate: (value: any, args: any) => {
        const decimals = args.length > 0 ? args[0] : '*';
        const separator = args.length > 1 ? args[1] : '.';

        if (value === null || value === undefined || value === '') {
            return {
                valid: false,
            };
        }
        if (Number(decimals) === 0) {
            return {
                valid: /^-?\d*$/.test(value),
            };
        }
        const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
        const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

        return {
            valid: regex.test(value),
        };
    },
    message(field: string, args: any) {
        // eslint-disable-next-line no-restricted-globals
        if (args.length > 0 && !isNaN(args[0])) {
            return `'${ValidatorUtils.prettifyName(field)}' must have at no decimals`;
        }
        return `'${ValidatorUtils.prettifyName(field)}' must have at most ${args[0]} decimals`;
    },
};
