
































import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { TwSelect } from '@/app/components';
import { requiredValidator } from '@/app/validators';
import { BlockCategoryWrapper } from '../../constants';

extend('required', requiredValidator);

export default defineComponent({
    name: 'TaskDataframesConfiguration',
    model: {
        prop: 'dataframeParameters',
        event: 'update-dataframe-configuration',
    },
    props: {
        dataframeParameters: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameters: {
            type: Array,
            required: true,
        },
        dataframes: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    components: { TwSelect, ValidationObserver, ValidationProvider },
    setup(props, { emit }) {
        const dataframesRef = ref<any>(null);

        const dataframeConfig = reactive(
            !R.isNil(props.dataframeParameters as any) && !R.isEmpty(props.dataframeParameters as any)
                ? (props.dataframeParameters as any)
                : props.parameters.reduce((acc: any, param: any) => {
                      acc[param.name] = { task: null };
                      return acc;
                  }, {}),
        );

        const selectionChange = async () => {
            emit('update-dataframe-configuration', dataframeConfig);

            if (dataframesRef.value) {
                const isValid = await dataframesRef.value.validate();
                if (isValid) {
                    emit('dataframes-configured', dataframeConfig);
                }
            }
        };

        watch(
            () => dataframesRef.value,
            () => {
                selectionChange();
            },
        );

        return { dataframeConfig, BlockCategoryWrapper, dataframesRef, selectionChange };
    },
});
