






























































































































import { computed, defineComponent, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { S } from '@/app/utilities';
import { InputErrorIcon, Toggle, ConfirmButton } from '@/app/components';
import { useParameter } from '@/modules/workflow-designer/composable';
import { InputParameter } from '@/modules/workflow-designer/types';
import ParameterValidation from '../ParameterValidation.vue';

export default defineComponent({
    name: 'BooleanParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        isRoot: {
            type: Boolean,
            default: true,
        },
    },
    components: { InputErrorIcon, Toggle, ParameterValidation, ConfirmButton },
    setup(props, { emit }) {
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);

        const { currentValue, isArray, addAnother, deleteEntry, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                if (!S.has('value', incomingValue) || R.isNil(incomingValue?.value)) {
                    // Case no existing value defined
                    if (S.has('default', props.parameter.validation)) {
                        // Case default value defined in validation information
                        if (props.parameter.multiple) {
                            if (!props.parameter.validation.default) {
                                return [];
                            }

                            if (R.is(Array, props.parameter.validation.default)) {
                                return !!props.parameter.validation.default;
                            }
                            return props.parameter.validation.default ? [!!props.parameter.validation.default] : [];
                        }
                        return props.parameter.validation.default ? !!props.parameter.validation.default : false;
                    }
                    // Case no default value defined in validation information then take default empty value
                    return props.parameter.multiple ? [] : false;
                }
                if (S.has('value', incomingValue) && incomingValue?.value && props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking multiple values
                    return R.is(Array, incomingValue.value) ? incomingValue.value : [incomingValue.value];
                }
                if (!R.isNil(incomingValue.value) && !props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking a single value
                    return !R.isNil(incomingValue.value) && !R.isEmpty(incomingValue.value)
                        ? incomingValue.value
                        : false;
                }
                return props.parameter.multiple ? [] : false;
            },

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    emit('change', { value: currentValue.value });
                }
            },
        );

        // Set the initial value on load
        change();

        return { currentValue, isArray, addAnother, deleteEntry, change, changeDate };
    },
});
