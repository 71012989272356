var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"modals"}},[(_vm.showUnlockConfirmation)?_c('ConfirmModal',{attrs:{"cancelButtonText":"Cancel","message":"Are you sure you want unlock the workflow for editing? This will lose any resuts generated by this workflow!","okButtonText":"Unlock","title":"Unlock workflow"},on:{"cancel":function($event){_vm.showUnlockConfirmation = false},"ok":function($event){_vm.$emit('unlock');
                _vm.showUnlockConfirmation = false;}}}):_vm._e()],1),_c('v-popover',{staticClass:"flex flex-row items-center group",attrs:{"trigger":"hover focus","placement":"top","delay":{ show: 500 }}},[_vm._t("default",function(){return [_c('div',{staticClass:"flex flex-row items-center rounded",class:{
                    'text-neutral-600 ': !_vm.canBeReopened || _vm.disabled,
                    'text-primary-500 hover:text-primary-700': _vm.canBeReopened && !_vm.disabled,
                }},[_c('div',{staticClass:"flex flex-row items-center justify-center space-x-1",class:{
                        'cursor-pointer': _vm.canBeReopened && !_vm.disabled,
                        'cursor-not-allowed': !_vm.canBeReopened || _vm.disabled,
                    },on:{"click":function($event){_vm.canBeReopened && !_vm.disabled ? (_vm.showUnlockConfirmation = true) : null}}},[_c('div',[_c('LockClosedIcon',{staticClass:"block w-6 h-6",class:{
                                'text-neutral-500 ': !_vm.canBeReopened || _vm.disabled,
                                'group-hover:hidden group-hover:w-0 group-hover:h-0': _vm.canBeReopened,
                            }}),_c('LockOpenIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.canBeReopened),expression:"canBeReopened"}],staticClass:"hidden w-6 h-6 group-hover:block"})],1),_c('div',{staticClass:"justify-center hidden text-sm capitalize lg:flex whitespace-nowrap"},[(_vm.canBeReopened)?[_vm._v("Edit")]:[_vm._v("Locked")]],2)])])]}),_c('div',{staticClass:"flex flex-col flex-shrink-0 w-auto text-white border rounded shadow-lg flex-end bg-neutral-900 border-neutral-900 flex-no-grow",attrs:{"slot":"popover"},slot:"popover"},[(_vm.canBeReopened)?[_c('p',[_vm._v("Make workflow editable again. Keep in mind that")]),_c('p',[_vm._v("configured schedules won't work and any results")]),_c('p',[_vm._v("will be removed")])]:[_c('p',[_vm._v("Since the result(s) of this workflow were made")]),_c('p',[_vm._v("available in marketplace, editing this workflow")]),_c('p',[_vm._v("is not allowed")])]],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }