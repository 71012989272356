














































import { defineComponent, computed } from '@vue/composition-api';
import { BlockCategoryWrapper, BlockCategory } from '../../constants';

export default defineComponent({
    name: 'ResultsViewTask',
    props: {
        task: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        stopClickPropagation: {
            type: Boolean,
            default: true,
        },
        isBlockListExpanded: {
            type: Boolean,
            default: true,
        },
    },
    components: {},
    setup(props) {
        const blockCategory = computed(() => BlockCategoryWrapper.find(props.task.block.category as BlockCategory));
        const blockType = computed(() => {
            return props.task.block.type;
        });

        const label = computed(() => {
            if (blockType.value !== null) {
                return `${blockCategory.value?.label} (${blockType})`;
            }
            return blockType;
        });
        // keep since more block types will be added
        const colour = computed(() => blockCategory.value?.colour);

        const iconHtml = computed(() => {
            return blockCategory.value?.iconHtml;
        });

        return {
            label,
            colour,
            iconHtml,
            blockCategory,
        };
    },
});
