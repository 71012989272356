import * as R from 'ramda';

export function useFileHarvesterHelper() {
    /**
     * Crops array of values and array of objects down to a specific limit (determined by arraySizeLimit)
     */
    const reduceSampleValues = (obj: any, limit: number): { obj: any; cropped: boolean } => {
        let cropped = false;
        if (typeof obj === 'object') {
            Object.keys(obj).forEach((k) => {
                if (typeof obj[k] === 'object' && obj[k] !== null) {
                    if (obj[k] instanceof Array && obj[k].length > limit) {
                        let isArray = false; // if at least 1 of array's values is array then it means there are more nested levels
                        for (let i = 0; i < obj[k].length; i++) {
                            if (obj[k][i] instanceof Array && obj[k][i] !== null) {
                                isArray = true;
                            }
                        }

                        if (!isArray) {
                            obj[k].splice(limit);
                            cropped = true;
                        }
                    }
                    const { cropped: innerCropped } = reduceSampleValues(obj[k], limit);
                    cropped = cropped || innerCropped;
                }
            });
        }
        return { obj, cropped };
    };

    const getAllPaths = (obj: any, prefix: string, key: string): string[] => {
        if (obj instanceof Array) {
            const allPaths = [];
            if (key !== '') {
                allPaths.push(prefix + key);
            }
            let newPrefix = prefix;
            if (prefix[prefix.length - 1] !== '||') {
                newPrefix = `${prefix}${'||'}`;
            }
            if (newPrefix === '||') {
                newPrefix = '';
            }
            let p: any = [];
            for (let k = 0; k < obj.length; k += 1) {
                p = p.concat(getAllPaths(obj[k], newPrefix, `${key}`));
            }
            for (let j = 0; j < p.length; j += 1) {
                allPaths.push(p[j]);
            }

            return [...new Set(allPaths)];
        }
        if (obj instanceof Object) {
            const keys = Object.keys(obj);
            const allPaths = [];
            if (key !== '') {
                allPaths.push(prefix + key);
            }
            for (let i = 0; i < keys.length; i += 1) {
                let newPrefix = `${prefix + key}${'||'}`;
                if (newPrefix === '||') {
                    newPrefix = '';
                }
                const p = getAllPaths(obj[keys[i]], newPrefix, keys[i]);
                for (let j = 0; j < p.length; j += 1) {
                    allPaths.push(p[j]);
                }
            }

            return [...new Set(allPaths)];
        }

        if (R.isEmpty(key)) return [];
        return [prefix + key];
    };

    const calculateFilteredPaths = (paths: any) => {
        const filteredPaths: any = [];
        paths.forEach((p: any) => {
            let pathWithoutSeparator = p.split('||');
            pathWithoutSeparator = pathWithoutSeparator.filter((v: any) => v !== '');
            if (!filteredPaths.find((fp: any) => fp.toString() === pathWithoutSeparator.toString())) {
                filteredPaths.push(pathWithoutSeparator);
            }
        });
        return filteredPaths;
    };

    const checkEmptyJSON = (json: any): boolean => {
        if (R.isNil(json) || R.isEmpty(json)) {
            return true;
        }
        if (R.type(json) === 'Array') {
            return checkEmptyJSON(json[0]);
        }
        return false;
    };

    return { reduceSampleValues, getAllPaths, calculateFilteredPaths, checkEmptyJSON };
}
