
























































import { defineComponent, watch } from '@vue/composition-api';
import * as R from 'ramda';
import TableViewTask from './TableViewTask.vue';
import { Task } from '../../types';
import { BlockCategoryWrapper } from '../../constants';

export default defineComponent({
    name: 'TableViewOverview',
    model: {
        prop: 'selected',
        event: 'update-selected',
    },
    props: {
        selected: {
            type: Object,
            default: null,
        },
        tasks: {
            type: Array,
            required: true,
        },
        taskMap: {
            type: Map,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        stopTaskClickPropagation: {
            type: Boolean,
            default: true,
        },
        columnsPerTask: {
            type: Object,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
    },
    components: { TableViewTask },
    setup(props, { emit, root }) {
        const allUpstreamTaskIds = (task: Task) => {
            if (!R.isNil(task) && task.upstreamTaskIds.length > 0) {
                const otherUpstreamTaskIds: string[] = task.upstreamTaskIds.reduce((acc: string[], id: string) => {
                    return [...acc, ...allUpstreamTaskIds(props.taskMap.get(id) as Task)];
                }, []);
                return [...task.upstreamTaskIds, ...otherUpstreamTaskIds];
            }
            return [];
        };

        const isHighlighted = (task: Task) => {
            return (
                props.selected === null ||
                props.selected.id === task.id ||
                allUpstreamTaskIds(props.selected as Task).includes(task.id)
            );
        };

        const allCategories = BlockCategoryWrapper.all();

        const clearSelected = () => {
            emit('update-selected', null);
        };

        const showSettings = (task: Task) => {
            emit('show-settings', task);
        };

        watch(
            () => props.tasks,
            (changedTasks: any) => {
                if (changedTasks.filter((task: Task) => props.selected && task.id === props.selected.id).length === 0) {
                    clearSelected();
                }

                // Defaulting to first task if none is selected
                if (R.isNil(props.selected) && changedTasks.length > 0) {
                    emit('update-selected', changedTasks[0]);
                }
            },
        );

        // Clearing selection in case escape is clicked
        const handleEscape = (e: KeyboardEvent) => {
            if ((e.key === 'Esc' || e.key === 'Escape') && !props.disabled) {
                clearSelected();
            }
        };

        const selectTask = (task: Task) => {
            emit('update-selected', task);
        };

        const isValid = (task: Task) => !props.invalidTaskIds.includes(task.id);

        document.addEventListener('keydown', handleEscape);
        root.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleEscape);
        });

        return { isHighlighted, showSettings, allCategories, selectTask, isValid };
    },
});
